import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import infrastructureService from "../../services/infrastructure.service";
import memorySizeHelper from "../../helpers/memory-size-helper";
import graphHelper from "../../helpers/graph-helper";
import PeriodToolbarReport from "../others/period-toolbar-report.component";

export default class ServerDailyReport extends Component {
  constructor(props) {
    super(props);
    this.getUsage = this.getUsage.bind(this);
    this.getData = this.getData.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      serverId: null,
      startDateTime: null,
      endDateTime: null,
      items: [],
      totalStorage: 0,
      totalMemory: 0,
      avgUsedMemory: 0,
      minUsedMemory: 0,
      maxUsedMemory: 0,
      minCpuAllActivePercent: 0,
      maxCpuAllActivePercent: 0,
      avgCpuAllActivePercent: 0,
      avgUsedStorage: 0,
      minUsedStorage: 0,
      maxUsedStorage: 0,
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      serverId: this.props.serverId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getUsage();
  }

  getUsage() {
    var body = {
      id: this.state.serverId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };

    infrastructureService
      .getDailyReport(body)
      .then((response) => {
        const responseData = response.data;
        this.setState({
          items: responseData.items,
          totalStorage: responseData.totalStorage,
          totalMemory: responseData.totalMemory,
          avgUsedMemory: responseData.avgUsedMemory,
          minUsedMemory: responseData.minUsedMemory,
          maxUsedMemory: responseData.maxUsedMemory,
          minCpuAllActivePercent: responseData.minCpuAllActivePercent,
          maxCpuAllActivePercent: responseData.maxCpuAllActivePercent,
          avgCpuAllActivePercent: responseData.avgCpuAllActivePercent,
          avgUsedStorage: responseData.avgUsedStorage,
          minUsedStorage: responseData.minUsedStorage,
          maxUsedStorage: responseData.maxUsedStorage,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getUsage();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data
    const items = this.state.items;
    const processorUsage = [];
    const memoryUsage = [];
    const storageUsage = [];
    const totalStorage = this.state.totalStorage;
    const totalMemory = this.state.totalMemory;
    const avgUsedMemory = this.state.avgUsedMemory;
    const minUsedMemory = this.state.minUsedMemory;
    const maxUsedMemory = this.state.maxUsedMemory;
    const minCpuAllActivePercent = this.state.minCpuAllActivePercent;
    const maxCpuAllActivePercent = this.state.maxCpuAllActivePercent;
    const avgCpuAllActivePercent = this.state.avgCpuAllActivePercent;
    const avgUsedStorage = this.state.avgUsedStorage;
    const minUsedStorage = this.state.minUsedStorage;
    const maxUsedStorage = this.state.maxUsedStorage;

    items.forEach((item) => {
      memoryUsage.push({
        period: item.reportDate,
        Avg: item.avgUsedMemory,
        Min: item.minUsedMemory,
        Max: item.maxUsedMemory,
      });

      storageUsage.push({
        period: item.reportDate,
        Avg: item.avgUsedStorage,
        Min: item.minUsedStorage,
        Max: item.maxUsedStorage,
      });

      processorUsage.push({
        period: item.reportDate,
        Avg: item.avgCpuAllActivePercent,
        Min: item.minCpuAllActivePercent,
        Max: item.maxCpuAllActivePercent,
      });
    });

    const minMemoryData = [
      {
        value: Math.round(((minUsedMemory * 100) / totalMemory) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((minUsedMemory * 100) / totalMemory) * 100) / 100,
      },
    ];
    const avgMemoryData = [
      {
        value: Math.round(((avgUsedMemory * 100) / totalMemory) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((avgUsedMemory * 100) / totalMemory) * 100) / 100,
      },
    ];
    const maxMemoryData = [
      {
        value: Math.round(((maxUsedMemory * 100) / totalMemory) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((maxUsedMemory * 100) / totalMemory) * 100) / 100,
      },
    ];
    const minStorageData = [
      {
        value: Math.round(((minUsedStorage * 100) / totalStorage) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((minUsedStorage * 100) / totalStorage) * 100) / 100,
      },
    ];

    const avgStorageData = [
      {
        value: Math.round(((avgUsedStorage * 100) / totalStorage) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((avgUsedStorage * 100) / totalStorage) * 100) / 100,
      },
    ];

    const maxStorageData = [
      {
        value: Math.round(((maxUsedStorage * 100) / totalStorage) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((maxUsedStorage * 100) / totalStorage) * 100) / 100,
      },
    ];
    const avgCpuAllActiveData = [
      {
        value: avgCpuAllActivePercent,
      },
      {
        value: 100 - avgCpuAllActivePercent,
      },
    ];
    const minCpuAllActiveData = [
      {
        value: minCpuAllActivePercent,
      },
      {
        value: 100 - minCpuAllActivePercent,
      },
    ];
    const maxCpuAllActiveData = [
      {
        value: maxCpuAllActivePercent,
      },
      {
        value: 100 - maxCpuAllActivePercent,
      },
    ];

    const ProcessorTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b> {payload[0].payload.Max}%<br />
              <b>Avg:</b> {payload[0].payload.Avg}%<br />
              <b>Min:</b> {payload[0].payload.Min}%
            </p>
          </div>
        );
      }

      return null;
    };

    const MemoryTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Max)}
              <br />
              <b>Avg:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Avg)}
              <br />
              <b>Min:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Min)}
              <br />
              <b>Total:</b> {memorySizeHelper.convertFromMegaBytes(totalMemory)}
            </p>
          </div>
        );
      }

      return null;
    };

    const StorageTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Max)}
              <br />
              <b>Avg:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Avg)}
              <br />
              <b>Min:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Min)}
              <br />
              <b>Total:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(totalStorage)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbarReport setPeriod={this.setPeriod} />
        <label className="server_historic_usage_title">Processor</label>
        <div className="server_daily_report_div">
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={processorUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<ProcessorTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  tickFormatter={graphHelper.formatPercent}
                />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={minCpuAllActiveData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {minCpuAllActiveData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(minCpuAllActivePercent)
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {minCpuAllActivePercent}%
          </label>
          <label className="server_current_usage_count_small"></label>
          <label className="server_current_usage_label">Min</label>
        </div>

        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={avgCpuAllActiveData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {avgCpuAllActiveData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(avgCpuAllActivePercent)
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {avgCpuAllActivePercent}%
          </label>
          <label className="server_current_usage_count_small"></label>
          <label className="server_current_usage_label">Avg</label>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={maxCpuAllActiveData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {maxCpuAllActiveData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(maxCpuAllActivePercent)
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {maxCpuAllActivePercent}%
          </label>
          <label className="server_current_usage_count_small"></label>
          <label className="server_current_usage_label">Max</label>
        </div>
        <hr />
        <label className="server_historic_usage_title">Memory</label>
        <div className="server_daily_report_div">
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={memoryUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<MemoryTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, totalMemory]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <YAxis />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={minMemoryData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {minMemoryData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (minUsedMemory * 100) / totalMemory
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((minUsedMemory * 100) / totalMemory) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(minUsedMemory) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalMemory)}
          </label>
          <label className="server_current_usage_label">Min</label>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={avgMemoryData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {avgMemoryData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (avgUsedMemory * 100) / totalMemory
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((avgUsedMemory * 100) / totalMemory) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(avgUsedMemory) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalMemory)}
          </label>
          <label className="server_current_usage_label">Avg</label>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={maxMemoryData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {maxMemoryData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (maxUsedMemory * 100) / totalMemory
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((maxUsedMemory * 100) / totalMemory) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(maxUsedMemory) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalMemory)}
          </label>
          <label className="server_current_usage_label">Max</label>
        </div>
        <hr />
        <label className="server_historic_usage_title">Storage</label>
        <div className="server_daily_report_div">
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={storageUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<StorageTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, totalStorage]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={minStorageData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {minStorageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (minUsedStorage * 100) / totalStorage
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((minUsedStorage * 100) / totalStorage) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(minUsedStorage) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalStorage)}
          </label>
          <label className="server_current_usage_label">Min</label>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={avgStorageData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {avgStorageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (avgUsedStorage * 100) / totalStorage
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((avgUsedStorage * 100) / totalStorage) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(avgUsedStorage) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalStorage)}
          </label>
          <label className="server_current_usage_label">Avg</label>
        </div>
        <div className="server_daily_report_div1">
          <div className="server_current_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={maxStorageData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {maxStorageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? graphHelper.getColor(
                              (maxUsedStorage * 100) / totalStorage
                            )
                          : "#ECEFF1"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="server_current_usage_count">
            {Math.round(((maxUsedStorage * 100) / totalStorage) * 100) / 100}%
          </label>
          <label className="server_current_usage_count_small">
            {" "}
            {memorySizeHelper.convertFromMegaBytes(maxUsedStorage) +
              " / " +
              memorySizeHelper.convertFromMegaBytes(totalStorage)}
          </label>
          <label className="server_current_usage_label">Max</label>
        </div>
      </div>
    );
  }
}
