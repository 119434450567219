import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import numberHelper from "../../helpers/number-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import {
  BarChart,
  Bar,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import textHelper from "../../helpers/text-helper";
import reactWebsitesService from "../../services/react-websites.service";

export default class ReactWebsiteVisitStatistics extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);

    this.state = {
      websiteId: "",
      startDateTime: "",
      endDateTime: "",
      syncIntervalId: null,
      visitStatistics: [],
      pages: [],
      totalVisits: 0,
      visitsInPeriod: 0,
      maxPages: 1,
      currentPage: 1,
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      websiteId: this.props.websiteId,
    });
    // this.initIntervals();
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  getStatistics() {
    var body = {
      id: this.state.websiteId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentPage - 1) * 50,
    };

    console.log(body);
    reactWebsitesService
      .getVisits(body)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          visitStatistics: responseData.visitStatistics,
          pages: responseData.pages,
          totalVisits: responseData.totalVisits,
          visitsInPeriod: responseData.visitsInPeriod,
          maxPages: Math.ceil(responseData.totalPages / 50),
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {
        this.props.onLoadingStateChange(false);
      });
  }

  backgroundSync() {
    this.getStatistics();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  pageBack() {
    const newCurrentPage = this.state.currentPage - 1;
    if (newCurrentPage === 0) {
      return;
    }

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      () => this.getStatistics()
    );
  }

  pageNext() {
    const newCurrentPage = this.state.currentPage + 1;
    if (newCurrentPage > this.state.maxPages) {
      return;
    }

    this.setState(
      {
        currentPage: newCurrentPage,
      },
      () => this.getStatistics()
    );
  }

  render() {
    // Data
    const visitStatistics = this.state.visitStatistics;
    const pages = this.state.pages;
    const totalVisits = this.state.totalVisits;
    const visitsInPeriod = this.state.visitsInPeriod;
    const currnetPage = this.state.currentPage;
    const maxPages = this.state.maxPages;

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Total visits: {payload[0].payload.totalVisits}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbar setPeriod={this.setPeriod} />

        <div className="website_visit_graph_container">
          <div className="website_visit_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={visitStatistics}>
                <YAxis />
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="totalVisits" fill="#b0bec5" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(visitsInPeriod)}
          </label>
        </div>

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>

        <table className="visit_table">
          <tr>
            <th className="visit_table_page_name">Page name</th>
            <th className="visit_table_column">Visits</th>
            <th className="visit_table_column">First visit</th>
            <th className="visit_table_column">Last visit</th>
          </tr>
        </table>
        {pages.map((page) => (
          <div className="row_page_item_div">
            <table className="visit_table">
              <tr>
                <td className="visit_table_page_name">
                  <label className="page_item_title">
                    {textHelper.getShortenText50Chars(page.pageTitle)}
                  </label>
                  <label className="page_item_path">{page.path}</label>
                </td>
                <td>
                  <label className="page_item_visits">
                    {numberHelper.format(page.visits)}
                  </label>
                </td>
                <td>
                  <label
                    className="page_item_first_seen"
                    title={page.firstVisitDate}
                  >
                    {page.firstVisitAgo}
                  </label>
                </td>
                <td>
                  <label
                    className="page_item_last_seen"
                    title={page.lastVisitDate}
                  >
                    {page.lastVisitAgo}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        ))}
        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>
      </div>
    );
  }
}
