import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import reactWebsitesService from "../../services/react-websites.service";
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Tooltip,
  ReferenceLine,
  YAxis,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import PeriodToolbarReport from "../others/period-toolbar-report.component";
import numberHelper from "../../helpers/number-helper";

export default class ReactWebsiteDailyReport extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      id: null,
      availabilityPercentage: 0,
      items: [],
      totalVisitsOfPages: 0,
    };
  }

  componentDidMount() {
    this.props.onLoadingStateChange(true);

    this.setState(
      {
        id: this.props.websiteId,
      },
      () => {
        this.getData();
      }
    );
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  getData() {
    var body = {
      id: this.state.id,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };
    reactWebsitesService
      .getDailyReport(body)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          availabilityPercentage: responseData.availabilityPercentage,
          items: responseData.items,
          totalVisitsOfPages: responseData.totalVisitsOfPages,
        });

        CookieHelper.extendValidity();

        this.props.onLoadingStateChange(false);
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const availabilityPercentage = this.state.availabilityPercentage;
    const items = this.state.items;
    const totalVisitsOfPages = this.state.totalVisitsOfPages;

    var availabilityPercentInPeriod = [];

    items.forEach((item) => {
      if (item.availabilityPercentage) {
        availabilityPercentInPeriod.push({
          reportDateTime: item.reportDateTime,
          availablePercentage: item.availabilityPercentage,
          notAvailablePercentage: 100 - item.availabilityPercentage,
        });
      } else {
        availabilityPercentInPeriod.push(null);
      }
    });

    const availabilityPercentData = [
      {
        value: availabilityPercentage,
      },
      {
        value: 100 - availabilityPercentage,
      },
    ];

    const AvailabilityTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Available: {payload[0].payload.availablePercentage}%<br />
              Unavailable: {payload[0].payload.notAvailablePercentage}%
            </p>
          </div>
        );
      }

      return null;
    };

    const TotalVisitsOfPagesTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total visits of pages: {payload[0].payload.totalVisitsOfPages}
            </p>
          </div>
        );
      }

      return null;
    };

    const TotalUniqueVisitorsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total unique visitors: {payload[0].payload.totalUniqueVisitors}
            </p>
          </div>
        );
      }

      return null;
    };

    const colors = ["#a5d6a7", "#ef9a9a"];

    return (
      <div>
        <PeriodToolbarReport setPeriod={this.setPeriod} />
        <label className="website_daily_report_title">Availability</label>

        <div className="website_daily_report_graph">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={availabilityPercentInPeriod}>
              <YAxis />
              <ReferenceLine y={0} stroke="#212121" />
              <Tooltip content={<AvailabilityTooltip />} />
              <Bar dataKey="availablePercentage" stackId="a" fill="#a5d6a7" />
              <Bar
                dataKey="notAvailablePercentage"
                stackId="a"
                fill="#ef9a9a"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div
          className="website_daily_report_div"
          style={{
            height: "225px",
            width: "30%",
            display: "inline-block",
            paddingTop: "75px",
            float: "right",
          }}
        >
          <div className="website_availability_last_graph">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={availabilityPercentData}
                  dataKey="value"
                  cx="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  startAngle={0}
                  endAngle={180}
                >
                  {availabilityPercentData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <label className="website_availability_count">
            {availabilityPercentage}%
          </label>
        </div>

        <div className="website_daily_report_container">
          <label className="website_daily_report_title">
            Total visits of pages
          </label>

          <div className="website_daily_report_graph_full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <YAxis />
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<TotalVisitsOfPagesTooltip />} />
                <Bar dataKey="totalVisitsOfPages" fill="#b0bec5" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(totalVisitsOfPages)}
          </label>
        </div>

        <div className="website_daily_report_container">
          <label className="website_daily_report_title">
            Total unique visitors
          </label>
          <div className="website_daily_report_graph_full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <YAxis />
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<TotalUniqueVisitorsTooltip />} />
                <Bar dataKey="totalUniqueVisitors" fill="#b0bec5" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
