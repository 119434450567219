import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import infrastructureService from "../../services/infrastructure.service";

export default class ServerInfo extends Component {
  constructor(props) {
    super(props);
    this.getInfo = this.getInfo.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);

    this.state = {
      id: null,
      ipAddress: null,
      metricPort: 0,
      serverName: null,
      serverPurpose: null,
      uptime: "",
      available: false,
      syncIntervalId: null,
    };
  }

  componentDidMount() {
    this.setState(
      {
        id: this.props.serverId,
      },
      () => {
        this.props.onLoadingStateChange(true);
        this.getInfo();
      }
    );
    this.initIntervals();
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  backgroundSync() {
    this.getInfo();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 10000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  getInfo() {
    infrastructureService
      .getInfo(this.state.id)
      .then((response) => {
        const serverInfo = response.data;

        this.setState({
          ipAddress: serverInfo.ipAddress,
          metricPort: serverInfo.metricsPort,
          serverName: serverInfo.serverName,
          serverPurpose: serverInfo.serverPurpose,
          uptime: serverInfo.uptime,
          available: serverInfo.available,
        });

        this.props.setBasicInfo(serverInfo.serverName);

        CookieHelper.extendValidity();

        this.props.onLoadingStateChange(false);
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const ipAddress = this.state.ipAddress;
    const metricPort = this.state.metricPort;
    const serverPurpose = this.state.serverPurpose;
    const uptime = this.state.uptime;
    const available = this.state.available;

    return (
      <div className="server_info_div">
        <label className="server_info_title">Purpose</label>
        <label className="server_info_value">{serverPurpose}</label>
        <label className="server_info_title">IP address</label>
        <label className="server_info_value">{ipAddress}</label>
        <label className="server_info_title">Metric port</label>
        <label className="server_info_value">{metricPort}</label>
        <label className="server_info_title">Uptime</label>
        <label className="server_info_value">{uptime}</label>
        <label className="server_info_title">Availability</label>
        {available ? (
          <div className="server_info_value">
            <div className="api_info_status_up" />
            Available
          </div>
        ) : (
          <div className="server_info_value">
            <div className="api_info_status_down" />
            Unavailable
          </div>
        )}
      </div>
    );
  }
}
