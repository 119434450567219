import http from "../helpers/http-common-account";

class AccountService {
  getAll() {
    return http.get(`/admin-console/`);
  }

  getInfo(id) {
    return http.get(`/admin-console/user-info/${id}`);
  }

  blockAccount(id) {
    return http.put(`/admin-console/block-account/${id}`);
  }
}

export default new AccountService();
