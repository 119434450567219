import http from "../helpers/http-common-authentication";

class AuthenticationService {
  resetPassword(id) {
    return http.post(`/admin-console/reset-password?id=${id}`);
  }

  delete(id) {
    return http.delete(`/admin-console/delete?id=${id}`);
  }

  getSessions(data) {
    return http.post("/admin-console/user-sessions", data);
  }
}

export default new AuthenticationService();
