import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import memorySizeHelper from "../../helpers/memory-size-helper";
import apiService from "../../services/api.service";

export default class ApiInfoHealth extends Component {
  constructor(props) {
    super(props);
    this.getInfo = this.getInfo.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.initIntervals = this.initIntervals.bind(this);

    this.state = {
      id: "",
      name: "",
      label: "",
      websiteUrl: "",
      endpointUrl: "",
      metrics: {},
      buildInfo: {},
      syncIntervalId: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        id: this.props.apiId,
      },
      () => {
        this.props.onLoadingStateChange(true);
        this.getInfo();
        this.initIntervals();
      }
    );
  }

  getInfo() {
    apiService
      .getInfoHealth(this.state.id)
      .then((response) => {
        const apiInfo = response.data;

        this.setState({
          name: apiInfo.name,
          label: apiInfo.label,
          websiteUrl: apiInfo.websiteUrl,
          endpointUrl: apiInfo.endpointUrl,
          metrics: apiInfo.metrics,
          buildInfo: apiInfo.buildInfo,
        });

        this.props.onLoadingStateChange(false);
        this.props.setBasicInfo(apiInfo.name);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getInfo();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 15000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  render() {
    // Data
    const label = this.state.label;
    const websiteUrl = this.state.websiteUrl;
    const endpointUrl = this.state.endpointUrl;
    const metrics = this.state.metrics;
    const buildInfo = this.state.buildInfo;

    return (
      <div>
        <div className="api_info_sec_div">
          <label className="api_info_sec_title">General info</label>
          <label className="api_info_title">Purpose</label>
          <label className="api_info_value">{label}</label>
          <label className="api_info_title">Endpoint url</label>
          <label className="api_info_value">{endpointUrl}</label>
          <label className="api_info_title">Project info url</label>
          <label className="api_info_value">
            <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
              {websiteUrl}
            </a>
          </label>
        </div>
        <div className="api_info_sec_div">
          <label className="api_info_sec_title">Metrics info</label>
          <label className="api_info_title">Uptime</label>
          <label className="api_info_value">{metrics.uptime}</label>
          <label className="api_info_title">Last check</label>
          <label className="api_info_value">{metrics.checkDate}</label>

          <hr />
          <label className="api_info_sec_title_small">Statuses</label>
          <label className="api_info_title">API</label>
          <div className="api_info_value">
            {metrics.apiStatus === "UP" ? (
              <div className="api_info_status_up" />
            ) : (
              <div className="api_info_status_down" />
            )}
            {metrics.apiStatus}
          </div>
          <label className="api_info_title">Ping</label>
          <div className="api_info_value">
            {metrics.pingStatus === "UP" ? (
              <div className="api_info_status_up" />
            ) : (
              <div className="api_info_status_down" />
            )}
            {metrics.pingStatus}
          </div>
          <label className="api_info_title">Liveness</label>
          <div className="api_info_value">
            {metrics.livenessStateStatus === "UP" ? (
              <div className="api_info_status_up" />
            ) : (
              <div className="api_info_status_down" />
            )}
            {metrics.livenessStateStatus}
          </div>
          <label className="api_info_title">Readiness</label>
          <div className="api_info_value">
            {metrics.readinessStateStatus === "UP" ? (
              <div className="api_info_status_up" />
            ) : (
              <div className="api_info_status_down" />
            )}
            {metrics.readinessStateStatus}
          </div>

          <hr />
          {metrics.data === undefined ? null : (
            <div>
              <label className="api_info_sec_title_small">Database</label>
              <label className="api_info_title">Name</label>
              <label className="api_info_value">{metrics.database}</label>
              <label className="api_info_title">Status</label>
              <div className="api_info_value">
                {metrics.databaseStatus === "UP" ? (
                  <div className="api_info_status_up" />
                ) : (
                  <div className="api_info_status_down" />
                )}
                {metrics.databaseStatus}
              </div>

              <hr />
            </div>
          )}

          <label className="api_info_sec_title_small">Resource</label>
          <label className="api_info_title">Memory</label>
          <label className="api_info_value">
            {memorySizeHelper.convertFromBytes(metrics.memoryUsed)}
          </label>
          <label className="api_info_title">CPU</label>
          <label className="api_info_value">{metrics.cpuUsage} %</label>
        </div>
        <div className="api_info_sec_div">
          <label className="api_info_sec_title">Build info</label>
          <hr />
          <label className="api_info_sec_title_small">Build</label>
          <label className="api_info_title">Group</label>
          <label className="api_info_value">{buildInfo.buildGroup}</label>
          <label className="api_info_title">Artifact</label>
          <label className="api_info_value">{buildInfo.buildArtifact}</label>
          <label className="api_info_title">Time</label>
          <label className="api_info_value">{buildInfo.buildTime}</label>
          <label className="api_info_title">Version</label>
          <label className="api_info_value">{buildInfo.buildVersion}</label>
          <hr />
          <label className="api_info_sec_title_small">JAVA</label>
          <label className="api_info_title">Version</label>
          <label className="api_info_value">{buildInfo.javaVersion}</label>
          <label className="api_info_title">Vendor</label>
          <label className="api_info_value">{buildInfo.javaVendorName}</label>
          <label className="api_info_title">Runtime</label>
          <label className="api_info_value">{buildInfo.javaRuntimeName}</label>
          <label className="api_info_title">Runtime version</label>
          <label className="api_info_value">
            {buildInfo.javaRuntimeVersion}
          </label>
          <label className="api_info_title">JVM</label>
          <label className="api_info_value">{buildInfo.javaJVMName}</label>
          <label className="api_info_title">JVM vendor</label>
          <label className="api_info_value">{buildInfo.javaJVMVendor}</label>
          <label className="api_info_title">JVM version</label>
          <label className="api_info_value">{buildInfo.javaJVMVersion}</label>
        </div>
      </div>
    );
  }
}
