import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import androidService from "../../services/android.service";

export default class AndroidAppInfo extends Component {
  constructor(props) {
    super(props);
    this.getInfo = this.getInfo.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.initIntervals = this.initIntervals.bind(this);

    this.state = {
      id: "",
      name: "",
      websiteUrl: "",
      googlePlayUrl: "",
      googlePlayConsoleUrl: "",
      totalInstallations: "",
      syncIntervalId: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        id: this.props.appId,
      },
      () => {
        this.props.onLoadingStateChange(true);
        this.getInfo();
        this.initIntervals();
      }
    );
  }

  getInfo() {
    androidService
      .getInfo(this.state.id)
      .then((response) => {
        const appInfo = response.data;

        this.setState({
          name: appInfo.name,
          websiteUrl: appInfo.websiteUrl,
          googlePlayUrl: appInfo.googlePlayUrl,
          googlePlayConsoleUrl: appInfo.googlePlayConsoleUrl,
          totalInstallations: appInfo.totalInstallations,
        });

        this.props.onLoadingStateChange(false);
        this.props.setBasicInfo(appInfo.name);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getInfo();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 15000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  render() {
    // Data
    const websiteUrl = this.state.websiteUrl;
    const googlePlayUrl = this.state.googlePlayUrl;
    const googlePlayConsoleUrl = this.state.googlePlayConsoleUrl;
    const totalInstallations = this.state.totalInstallations;

    return (
      <div className="api_info_sec_div">
        <label className="api_info_title">Total installations</label>
        <label className="api_info_value">{totalInstallations}</label>
        <label className="api_info_title">Website URL</label>
        <label className="api_info_value">
          <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
            {websiteUrl}
          </a>
        </label>
        <label className="api_info_title">Google Play URL</label>
        <label className="api_info_value">
          <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
            {googlePlayUrl}
          </a>
        </label>
        <label className="api_info_title">Google Play Console URL</label>
        <label className="api_info_value">
          <a
            href={googlePlayConsoleUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {googlePlayConsoleUrl}
          </a>
        </label>
      </div>
    );
  }
}
