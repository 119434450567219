import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link, Navigate } from "react-router-dom";
import authenticationService from "../../services/authentication.service";
import accountService from "../../services/account.service";
import memorySizeHelper from "../../helpers/memory-size-helper";

export default class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.getSessions = this.getSessions.bind(this);
    this.getBody = this.getBody.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.blockAccount = this.blockAccount.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    this.state = {
      id: "",
      email: "",
      databaseId: "",
      storageId: "",
      authenticationId: "",
      totalStorageCapacity: 0,
      usedStorageCapacity: 0,
      role: "",
      subscription: "",
      blocked: "",
      dateOfRegistration: "",
      loadingItems: false,
      tabKey: "DETAILS",
      sessions: [],
      total: 0,
      maxSessionPages: 1,
      currentSessionPage: 1,
      goBack: false,
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var id = pathSplit[pathSplit.length - 1];

    document.title = "Account detail | Console - davidsimak.dev";

    this.setState(
      {
        id: id,
        loadingItems: true,
      },
      () => this.getData()
    );
  }

  getBody() {
    const body = {
      id: this.state.authenticationId,
      offset: (this.state.currentSessionPage - 1) * 50,
    };

    return body;
  }

  getSessions() {
    this.setState({
      loadingItems: true,
    });
    authenticationService
      .getSessions(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          sessions: responseData.sessions,
          total: responseData.total,
          maxSessionsPages: Math.ceil(responseData.total / 50),
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  getData() {
    accountService
      .getInfo(this.state.id)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          id: responseData.id,
          email: responseData.email,
          databaseId: responseData.databaseId,
          storageId: responseData.storageId,
          authenticationId: responseData.authenticationId,
          totalStorageCapacity: responseData.totalStorageCapacity,
          usedStorageCapacity: responseData.usedStorageCapacity,
          role: responseData.role,
          subscription: responseData.subscription,
          blocked: responseData.blocked,
          dateOfRegistration: responseData.dateOfRegistration,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  pageBack() {
    const newCurrentSessionPage = this.state.currentSessionPage - 1;
    if (newCurrentSessionPage === 0) {
      return;
    }

    this.setState(
      {
        currentSessionPage: newCurrentSessionPage,
      },
      () => this.getSessions()
    );
  }

  pageNext() {
    const newCurrentSessionPage = this.state.currentSessionPage + 1;
    if (newCurrentSessionPage > this.state.maxSessionPages) {
      return;
    }

    this.setState(
      {
        currentSessionPage: newCurrentSessionPage,
      },
      () => this.getSessions()
    );
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });

    if (value === "SESSIONS") {
      this.getSessions();
    } else {
      this.getInfo();
    }
  }

  deleteAccount() {
    if (!window.confirm("Do you realy want to delete this accont?")) {
      return;
    }
    this.setState({
      loadingItems: true,
    });

    authenticationService
      .delete(this.state.authenticationId)
      .then((response) => {
        this.setState({
          goBack: true,
        });
        alert("Account is deleted");
        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  blockAccount() {
    const blockString = !this.state.blocked ? "block" : "unblock";

    if (
      !window.confirm("Do you realy want to " + blockString + " this accont?")
    ) {
      return;
    }
    this.setState({
      loadingItems: true,
    });
    accountService
      .blockAccount(this.state.id)
      .then((response) => {
        const blockedString = !this.state.blocked ? "blocked" : "unblocked";
        alert("Account is " + blockedString);
        this.getData();
        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  resetPassword() {
    if (
      !window.confirm("Do you realy want to reset password for this accont?")
    ) {
      return;
    }
    this.setState({
      loadingItems: true,
    });
    authenticationService
      .resetPassword(this.state.authenticationId)
      .then((response) => {
        alert("Password was reseted");
        this.setState({
          loadingItems: false,
        });
        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const id = this.state.id;
    const email = this.state.email;
    const databaseId = this.state.databaseId;
    const storageId = this.state.storageId;
    const authenticationId = this.state.authenticationId;
    const totalStorageCapacity = this.state.totalStorageCapacity;
    const usedStorageCapacity = this.state.usedStorageCapacity;
    const role = this.state.role;
    const subscription = this.state.subscription;
    const blocked = this.state.blocked;
    const dateOfRegistration = this.state.dateOfRegistration;

    const loadingItems = this.state.loadingItems;
    const tabKey = this.state.tabKey;

    const sessions = this.state.sessions;
    const total = this.state.total;
    const currnetPage = this.state.currentSessionPage;
    const maxPages = this.state.maxSessionPages;
    const goBack = this.state.goBack;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/accounts`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Account detail</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="http_trace_group_detail_tabs">
              <button
                className={
                  "http_trace_group_detail_tabs_button " +
                  (tabKey === "DETAILS" ? "website_tabs_button_selected" : "")
                }
                onClick={() => this.setTabKey("DETAILS")}
              >
                Details
              </button>
              <button
                className={
                  "http_trace_group_detail_tabs_button " +
                  (tabKey === "SESSIONS" ? "website_tabs_button_selected" : "")
                }
                onClick={() => this.setTabKey("SESSIONS")}
              >
                Sessions
              </button>
            </div>
            <br />
            {tabKey === "DETAILS" ? (
              <div>
                <div className="log_detail_container">
                  <label className="log_detail_message">{email}</label>
                  <hr />
                  <label className="api_info_title">Role</label>
                  <label className="api_info_value">{role}</label>
                  <label className="api_info_title">Subsription</label>
                  <label className="api_info_value">{subscription}</label>
                  <label className="api_info_title">Active</label>
                  <div className="api_info_value">
                    {!blocked ? (
                      <div className="api_info_status_up" />
                    ) : (
                      <div className="api_info_status_down" />
                    )}
                  </div>
                  <label className="api_info_title">Date of registration</label>
                  <label className="api_info_value">{dateOfRegistration}</label>
                  <hr />
                  <label className="api_info_title">Account ID</label>
                  <label className="api_info_value">{id}</label>
                  <label className="api_info_title">Authentication ID</label>
                  <label className="api_info_value">{authenticationId}</label>
                  <label className="api_info_title">Database ID</label>
                  <label className="api_info_value">{databaseId}</label>
                  <label className="api_info_title">Storage ID</label>
                  <label className="api_info_value">{storageId}</label>
                  <hr />
                  <label className="api_info_title">Used storage</label>
                  <label className="api_info_value">
                    {memorySizeHelper.convertFromBytes(usedStorageCapacity)}
                  </label>
                  <label className="api_info_title">Total storage</label>
                  <label className="api_info_value">
                    {memorySizeHelper.convertFromBytes(totalStorageCapacity)}
                  </label>
                  <hr />
                  <div style={{ width: "100%" }}>
                    <button
                      className="account_button"
                      onClick={this.deleteAccount}
                    >
                      Delete account
                    </button>
                    <button
                      className="account_button"
                      onClick={this.resetPassword}
                    >
                      Reset password
                    </button>
                    <button
                      className="account_button"
                      onClick={this.blockAccount}
                    >
                      Block account
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {tabKey === "SESSIONS" ? (
              <div>
                <div className="log_paging_container">
                  <button
                    className="action_button_log action_log_page_back"
                    disabled={currnetPage === 1}
                    onClick={this.pageBack}
                  />
                  <label className="log_page_count">
                    {currnetPage}/{maxPages}
                  </label>
                  <button
                    className="action_button_log action_log_page_next"
                    disabled={currnetPage === maxPages}
                    onClick={this.pageNext}
                  />
                </div>
                <table className="logs_table logs_table_top">
                  <tr>
                    <th
                      className="session_table_description"
                      style={{ width: "40%", textAlign: "center" }}
                    >
                      ID
                    </th>
                    <th
                      className="session_table_column"
                      style={{ width: "20%", textAlign: "center" }}
                    >
                      Created
                    </th>
                    <th
                      className="session_table_small_column"
                      style={{ width: "20%", textAlign: "center" }}
                    >
                      Last access
                    </th>
                    <th
                      className="session_table_small_column"
                      style={{ width: "20%", textAlign: "center" }}
                    >
                      Valid to
                    </th>
                  </tr>
                </table>
                {sessions.map((session) => (
                  <div className="row_website_item">
                    <div className="row_logs_item_div">
                      <table className="logs_table">
                        <tr>
                          <td
                            className="session_table_description"
                            style={{ width: "40%", textAlign: "center" }}
                          >
                            {session.id}
                          </td>
                          <td
                            className="session_table_column"
                            style={{ width: "20%", textAlign: "center" }}
                          >
                            {session.serverCreationDate}
                          </td>
                          <td
                            className="session_table_column"
                            style={{ width: "20%", textAlign: "center" }}
                          >
                            {session.serverLastAccessDate}
                          </td>
                          <td
                            className="session_table_column"
                            style={{ width: "20%", textAlign: "center" }}
                          >
                            {session.serverValidityDate}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
                <div className="log_paging_container">
                  <button
                    className="action_button_log action_log_page_back"
                    disabled={currnetPage === 1}
                    onClick={this.pageBack}
                  />
                  <label className="log_page_count">
                    {currnetPage}/{maxPages}
                  </label>
                  <button
                    className="action_button_log action_log_page_next"
                    disabled={currnetPage === maxPages}
                    onClick={this.pageNext}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {goBack ? <Navigate to="/accounts" replace={true} /> : null}
      </div>
    );
  }
}
