import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import ReactWebsiteInfo from "./react-website-info.component";
import ReactWebsiteVisitStatistics from "./react-website-visit-statistics.component";
import ReactWebsiteAvailabilityStatistics from "./react-website-availability-statistics.component";
import ReactWebsiteDailyReport from "./react-website-daily-report.component";

export default class ReactWebsite extends Component {
  constructor(props) {
    super(props);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.setName = this.setName.bind(this);

    this.state = {
      id: null,
      name: null,
      loadingItems: true,
      tabKey: "",
    };
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var websiteId = pathSplit[pathSplit.length - 1];
    this.setState({
      id: websiteId,
      tabKey: "INFO",
    });
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  setName(name) {
    this.setState({
      name: name,
    });
  }

  render() {
    // Data
    const id = this.state.id;
    const name = this.state.name;
    const tabKey = this.state.tabKey;

    // UI changes
    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/react-websites">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>{name} (React website)</h1>
            </div>

            <nav>
              <ul>
                <li>
                  <div className="dropdown">
                    <button
                      title="Account Info"
                      className="action_button action_account_info"
                    />
                    <div className="dropdown_content dropdown_content_account_info">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.davidsimak.dev/account/"
                      >
                        Account Info
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={this.singOut}>Sign out</a>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                <div className="website_tabs">
                  <button
                    className={
                      "website_tabs_button " +
                      (tabKey === "INFO" ? "website_tabs_button_selected" : "")
                    }
                    onClick={() => this.setTabKey("INFO")}
                  >
                    Overview
                  </button>
                  <button
                    className={
                      "website_tabs_button " +
                      (tabKey === "VISITS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("VISITS")}
                  >
                    Visits
                  </button>
                  <button
                    className={
                      "website_tabs_button " +
                      (tabKey === "AVAILABILITY"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("AVAILABILITY")}
                  >
                    Availability
                  </button>
                  <button
                    className={
                      "website_tabs_button " +
                      (tabKey === "REPORTS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("REPORTS")}
                  >
                    Daily reports
                  </button>
                </div>

                {tabKey === "INFO" ? (
                  <ReactWebsiteInfo
                    websiteId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                    setName={this.setName}
                  />
                ) : null}

                {tabKey === "VISITS" ? (
                  <ReactWebsiteVisitStatistics
                    websiteId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "AVAILABILITY" ? (
                  <ReactWebsiteAvailabilityStatistics
                    websiteId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "REPORTS" ? (
                  <ReactWebsiteDailyReport
                    websiteId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
