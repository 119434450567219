import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import apiService from "../../services/api.service";
import numberHelper from "../../helpers/number-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import {
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";

export default class ApiHttpTraceGroupDetail extends Component {
  constructor(props) {
    super(props);
    this.getOverview = this.getOverview.bind(this);
    this.findColorForPieChart = this.findColorForPieChart.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.getBody = this.getBody.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.getHistory = this.getHistory.bind(this);

    this.state = {
      lastSeenAgoIn: "",
      firstSeenAgoIn: "",
      lastSeenDate: "",
      firstSeenDate: "",
      totalAmount: 0,
      method: "",
      patternMatch: "",
      last24HoursEvents: [],
      last1HourEvents: [],
      totalLast24HoursEvents: 0,
      totalLast1HourEvents: 0,
      avgDuration: 0,
      maxDuration: 0,
      minDuration: 0,
      avgDurationLast24Hours: 0,
      maxDurationLast24Hours: 0,
      minDurationLast24Hours: 0,
      avgDurationLast1Hour: 0,
      maxDurationLast1Hour: 0,
      minDurationLast1Hour: 0,
      totalResponseStatusesLast24Hours: [],
      totalResponseStatusesLast1Hour: [],
      loadingItems: false,
      tabKey: "OVERVIEW",
      startDateTime: null,
      endDateTime: null,
      traces: [],
      metrics: [],
      total: 0,
      maxLogPages: 1,
      currentLogPage: 1,
      total: 0,
      avgDuration: 0,
      maxDuration: 0,
      minDuration: 0,
      traces: [],
      metrics: [],
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var httpTraceGroupId = pathSplit[pathSplit.length - 1];
    var apiId = pathSplit[pathSplit.length - 3];

    document.title = "Http trace group detail | Console - davidsimak.dev";

    this.setState(
      {
        apiId: apiId,
        httpTraceGroupId: httpTraceGroupId,
        loadingItems: true,
      },
      () => this.getOverview()
    );

    this.initIntervals();
  }

  getOverview() {
    apiService
      .getHttpTraceGroup(this.state.httpTraceGroupId)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          lastSeenAgoIn: responseData.lastSeenAgoIn,
          firstSeenAgoIn: responseData.firstSeenAgoIn,
          lastSeenDate: responseData.lastSeenDate,
          firstSeenDate: responseData.firstSeenDate,
          totalAmount: responseData.totalAmount,
          method: responseData.method,
          patternMatch: responseData.patternMatch,
          last24HoursEvents: responseData.last24HoursEvents,
          last1HourEvents: responseData.last1HourEvents,
          totalLast24HoursEvents: responseData.totalLast24HoursEvents,
          totalLast1HourEvents: responseData.totalLast1HourEvents,
          avgDuration: responseData.avgDuration,
          maxDuration: responseData.maxDuration,
          minDuration: responseData.minDuration,
          avgDurationLast24Hours: responseData.avgDurationLast24Hours,
          maxDurationLast24Hours: responseData.maxDurationLast24Hours,
          minDurationLast24Hours: responseData.minDurationLast24Hours,
          avgDurationLast1Hour: responseData.avgDurationLast1Hour,
          maxDurationLast1Hour: responseData.maxDurationLast1Hour,
          minDurationLast1Hour: responseData.minDurationLast1Hour,
          totalResponseStatusesLast1Hour:
            responseData.totalResponseStatusesLast1Hour,
          totalResponseStatusesLast24Hours:
            responseData.totalResponseStatusesLast24Hours,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    if (this.state.tabKey === "OVERVIEW") {
      this.getOverview();
    }
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 20000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  findColorForPieChart(entry) {
    console.log(entry);
    const status = parseInt(entry.name);

    if (status < 200) {
      return "#b0bec5";
    }

    if (status < 300) {
      return "#a5d6a7";
    }

    if (status < 400) {
      return "#80cbc4";
    }

    if (status < 500) {
      return "#ffcc80";
    }

    return "#ef9a9a";
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getHistory()
    );
  }

  getBody() {
    return {
      id: this.state.httpTraceGroupId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentLogPage - 1) * 50,
    };
  }

  pageBack() {
    const newCurrentLogPage = this.state.currentLogPage - 1;
    if (newCurrentLogPage === 0) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getHistory()
    );
  }

  pageNext() {
    const newCurrentLogPage = this.state.currentLogPage + 1;
    if (newCurrentLogPage > this.state.maxLogPages) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getHistory()
    );
  }

  getHistory() {
    this.setState({
      loadingItems: true,
    });
    apiService
      .getHttpTraceHistory(this.getBody())
      .then((response) => {
        const responseData = response.data;
        console.log(responseData);

        this.setState({
          traces: responseData.traces,
          metrics: responseData.metrics,
          total: responseData.total,
          maxLogPages: Math.ceil(responseData.total / 50),
          minDuration: responseData.minDuration,
          maxDuration: responseData.maxDuration,
          avgDuration: responseData.avgDuration,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const lastSeenAgoIn = this.state.lastSeenAgoIn;
    const firstSeenAgoIn = this.state.firstSeenAgoIn;
    const lastSeenDate = this.state.lastSeenDate;
    const firstSeenDate = this.state.firstSeenDate;
    const totalAmount = this.state.totalAmount;
    const method = this.state.method;
    const patternMatch = this.state.patternMatch;
    const last24HoursEvents = this.state.last24HoursEvents;
    const last1HourEvents = this.state.last1HourEvents;
    const totalLast24HoursEvents = this.state.totalLast24HoursEvents;
    const totalLast1HourEvents = this.state.totalLast1HourEvents;
    const avgDurationLast24Hours = this.state.avgDurationLast24Hours;
    const maxDurationLast24Hours = this.state.maxDurationLast24Hours;
    const minDurationLast24Hours = this.state.minDurationLast24Hours;
    const avgDurationLast1Hour = this.state.avgDurationLast1Hour;
    const maxDurationLast1Hour = this.state.maxDurationLast1Hour;
    const minDurationLast1Hour = this.state.minDurationLast1Hour;
    const totalResponseStatusesLast24Hours =
      this.state.totalResponseStatusesLast24Hours;
    const totalResponseStatusesLast1hour =
      this.state.totalResponseStatusesLast1Hour;

    const loadingItems = this.state.loadingItems;
    const tabKey = this.state.tabKey;

    const traces = this.state.traces;
    const metrics = this.state.metrics;
    const avgDuration = this.state.avgDuration;
    const maxDuration = this.state.maxDuration;
    const minDuration = this.state.minDuration;

    metrics.forEach((metric) => {
      var total100 = 0;
      var total200 = 0;
      var total300 = 0;
      var total400 = 0;
      var total500 = 0;

      Object.keys(metric.statusesCounts).forEach((responseStatus) => {
        const nResponseStatus = parseInt(responseStatus);

        if (100 <= nResponseStatus && nResponseStatus < 200) {
          total100 += metric.statusesCounts[responseStatus];
        }

        if (200 <= nResponseStatus && nResponseStatus < 300) {
          total200 += metric.statusesCounts[responseStatus];
        }

        if (300 <= nResponseStatus && nResponseStatus < 400) {
          total300 += metric.statusesCounts[responseStatus];
        }

        if (400 <= nResponseStatus && nResponseStatus < 500) {
          total400 += metric.statusesCounts[responseStatus];
        }

        if (500 <= nResponseStatus && nResponseStatus < 600) {
          total500 += metric.statusesCounts[responseStatus];
        }
      });

      metric["100-199"] = total100;
      metric["200-299"] = total200;
      metric["300-399"] = total300;
      metric["400-199"] = total400;
      metric["500-599"] = total500;
    });

    const total = this.state.total;
    const currnetPage = this.state.currentLogPage;
    const maxPages = this.state.maxLogPages;

    const CallsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              <b>Calls:</b> {numberHelper.format(payload[0].payload.Calls)}
            </p>
          </div>
        );
      }

      return null;
    };

    const ChartTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-desc">
              <b>{payload[0].payload.name}:</b>{" "}
              {numberHelper.format(payload[0].payload.value)}
            </p>
          </div>
        );
      }

      return null;
    };

    const HttpTracesTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.Total)} <br />
              {Object.keys(payload[0].payload.statusesCounts).map(
                (responseStatus) =>
                  responseStatus +
                  ": " +
                  payload[0].payload.statusesCounts[responseStatus] +
                  " \n"
              )}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/apis/${this.state.apiId}?tabKey=HTTP_TRACE_GROUPS`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>{patternMatch}</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="log_detail_container">
              <div className="http_trace_group_detail_tabs">
                <button
                  className={
                    "http_trace_group_detail_tabs_button " +
                    (tabKey === "OVERVIEW"
                      ? "website_tabs_button_selected"
                      : "")
                  }
                  onClick={() => this.setTabKey("OVERVIEW")}
                >
                  Overview
                </button>
                <button
                  className={
                    "http_trace_group_detail_tabs_button " +
                    (tabKey === "HISTORY" ? "website_tabs_button_selected" : "")
                  }
                  onClick={() => this.setTabKey("HISTORY")}
                >
                  History
                </button>
              </div>
              <br />
              {tabKey === "OVERVIEW" ? (
                <div>
                  <div className="log_group_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Method
                    </label>
                    <label className="log_detail_basic_info_value">
                      {method}
                    </label>
                    <label className="log_detail_basic_info_sub_value"></label>
                  </div>
                  <div className="log_group_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">Count</label>
                    <label className="log_detail_basic_info_value">
                      {totalAmount}
                    </label>
                    <label className="log_detail_basic_info_sub_value"></label>
                  </div>
                  <div className="log_group_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      First call
                    </label>
                    <label className="log_detail_basic_info_value">
                      {firstSeenAgoIn}
                    </label>
                    <label className="log_detail_basic_info_sub_value">
                      {firstSeenDate}
                    </label>
                  </div>
                  <div className="log_group_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Last call
                    </label>
                    <label className="log_detail_basic_info_value">
                      {lastSeenAgoIn}
                    </label>
                    <label className="log_detail_basic_info_sub_value">
                      {lastSeenDate}
                    </label>
                  </div>
                  <hr />

                  <label className="log_detail_title">Last hour</label>

                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Calls
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last1HourEvents}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<CallsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar dataKey="Calls" fill="#b0bec5" stackId="a" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Total: {totalLast1HourEvents}
                    </div>
                  </div>
                  <div
                    className="log_group_detail_graph_statistics_container"
                    style={{ float: "right" }}
                  >
                    <label className="log_group_detail_graph_statistics_title">
                      Response statuses
                    </label>
                    <div
                      className="log_group_detail_graph"
                      style={{ height: "220px" }}
                    >
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            dataKey="value"
                            data={totalResponseStatusesLast1hour}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label
                          >
                            {totalResponseStatusesLast1hour.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={this.findColorForPieChart(entry)}
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip content={<ChartTooltip />} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Min duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {minDurationLast1Hour ? minDurationLast1Hour : "-"} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Avg duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {avgDurationLast1Hour ? avgDurationLast1Hour : "-"} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Max duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {maxDurationLast1Hour ? maxDurationLast1Hour : "-"} ms
                    </label>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <label className="log_detail_title">Last 24 hours</label>

                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Calls
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last24HoursEvents}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<CallsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar dataKey="Calls" fill="#b0bec5" stackId="a" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Total: {totalLast24HoursEvents}
                    </div>
                  </div>
                  <div
                    className="log_group_detail_graph_statistics_container"
                    style={{ float: "right" }}
                  >
                    <label className="log_group_detail_graph_statistics_title">
                      Response statuses
                    </label>
                    <div
                      className="log_group_detail_graph"
                      style={{ height: "220px" }}
                    >
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            dataKey="value"
                            data={totalResponseStatusesLast24Hours}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                          >
                            {totalResponseStatusesLast24Hours.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={this.findColorForPieChart(entry)}
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip content={<ChartTooltip />} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Min duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {minDurationLast24Hours ? minDurationLast24Hours : "-"} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Avg duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {avgDurationLast24Hours ? avgDurationLast24Hours : "-"} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Max duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {maxDurationLast24Hours ? maxDurationLast24Hours : "-"} ms
                    </label>
                  </div>
                </div>
              ) : null}

              {tabKey === "HISTORY" ? (
                <div>
                  <PeriodToolbar setPeriod={this.setPeriod} />
                  <div className="api_logs_statistics_container">
                    <div className="api_logs_statistics_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={metrics}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<HttpTracesTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Legend />
                          <Bar dataKey="100-199" fill="#b0bec5" stackId="a" />
                          <Bar dataKey="200-299" fill="#a5d6a7" stackId="a" />
                          <Bar dataKey="300-399" fill="#80cbc4" stackId="a" />
                          <Bar dataKey="400-499" fill="#ffcc80" stackId="a" />
                          <Bar dataKey="500-599" fill="#ef9a9a" stackId="a" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <label className="api_logs_statistics_summary">
                      Total: {numberHelper.format(total)} <br />
                    </label>
                  </div>
                  <br />
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Min duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {minDuration ? minDuration : 0} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Avg duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {avgDuration ? avgDuration : 0} ms
                    </label>
                  </div>
                  <div className="http_trace_detail_basic_info_container">
                    <label className="log_detail_basic_info_title">
                      Max duration
                    </label>
                    <label className="log_detail_basic_info_value">
                      {maxDuration ? maxDuration : 0} ms
                    </label>
                  </div>
                  <br />
                  <div className="log_paging_container">
                    <button
                      className="action_button_log action_log_page_back"
                      disabled={currnetPage === 1}
                      onClick={this.pageBack}
                    />
                    <label className="log_page_count">
                      {currnetPage}/{maxPages}
                    </label>
                    <button
                      className="action_button_log action_log_page_next"
                      disabled={currnetPage === maxPages}
                      onClick={this.pageNext}
                    />
                  </div>

                  <table className="logs_table logs_table_top">
                    <tr>
                      <th className="traces_table_description">Description</th>
                      <th className="traces_table_small_column"></th>
                      <th className="traces_table_column">Date</th>
                      <th className="traces_table_small_column">Status</th>
                      <th className="traces_table_small_column">Duration</th>
                    </tr>
                  </table>
                  {traces.map((trace) => (
                    <div className="row_website_item">
                      <div className="row_logs_item_div">
                        <table className="logs_table">
                          <tr>
                            <td className="traces_table_description">
                              <label className="log_item_message">
                                {trace.path}
                              </label>
                              {trace.apiUsageId ? (
                                <label className="log_item_sessions">
                                  <b>API usage:</b> {trace.apiUsageId}
                                </label>
                              ) : null}
                              {trace.userSession ? (
                                <label className="log_item_sessions">
                                  <b>User session:</b> {trace.userSession}
                                </label>
                              ) : null}
                            </td>
                            <td className="traces_table_small_column">
                              <label className="logs_label_small_column"></label>
                            </td>
                            <td className="traces_table_column">
                              <label className="traces_label_column">
                                {trace.callDateTime}
                              </label>
                            </td>
                            <td className="traces_table_small_column">
                              <label className="traces_label_small_column">
                                {trace.responseStatus}
                              </label>
                            </td>
                            <td className="traces_table_small_column">
                              <label className="traces_label_small_column">
                                {trace.duration} ms
                              </label>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ))}

                  <div className="log_paging_container">
                    <button
                      className="action_button_log action_log_page_back"
                      disabled={currnetPage === 1}
                      onClick={this.pageBack}
                    />
                    <label className="log_page_count">
                      {currnetPage}/{maxPages}
                    </label>
                    <button
                      className="action_button_log action_log_page_next"
                      disabled={currnetPage === maxPages}
                      onClick={this.pageNext}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
