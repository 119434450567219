class MemorySizeHelper {
  convertFromMegaBytes(megaBytes) {
    const gigabyte = 1024;
    const terabyte = gigabyte * 1024;
    const petabyte = terabyte * 1024;

    if (megaBytes >= petabyte) {
      return this.format(megaBytes, petabyte, "PB");
    }

    if (megaBytes >= terabyte) {
      return this.format(megaBytes, terabyte, "TB");
    }

    if (megaBytes >= gigabyte) {
      return this.format(megaBytes, gigabyte, "GB");
    }

    return megaBytes + " MB";
  }

  format(value, divider, unit) {
    return Math.round((value / divider) * 100) / 100 + " " + unit;
  }

  convertFromBytes(bytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
    const terabyte = gigabyte * 1024;
    const petabyte = terabyte * 1024;

    if (bytes >= petabyte) {
      return this.format(bytes, petabyte, "PB");
    }

    if (bytes >= terabyte) {
      return this.format(bytes, terabyte, "TB");
    }

    if (bytes >= gigabyte) {
      return this.format(bytes, gigabyte, "GB");
    }

    if (bytes >= megabyte) {
      return this.format(bytes, megabyte, "MB");
    }

    if (bytes >= kilobyte) {
      return this.format(bytes, kilobyte, "KB");
    }

    return bytes + "B";
  }
}

export default new MemorySizeHelper();
