class NumberHelper {
  format(number) {
    if (number < 999) {
      return number;
    }

    if (number < 99999) {
      return (number / 1000).toFixed(1) + "k";
    }

    if (number < 999999) {
      return (number / 1000).toFixed(0) + "k";
    }

    if (number < 99999999) {
      return (number / 1000).toFixed(1) + "M";
    }

    if (number < 999999999) {
      return (number / 1000).toFixed(0) + "M";
    }

    if (number < 99999999999) {
      return (number / 1000).toFixed(1) + "B";
    }

    if (number < 999999999999) {
      return (number / 1000).toFixed(0) + "B";
    }
  }
}

export default new NumberHelper();
