import React, { Component } from "react";
import { Link } from "react-router-dom";
import CookieHelper from "../../helpers/cookie-helper";
import accountService from "../../services/account.service";

export default class AccountsList extends Component {
  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.getData = this.getData.bind(this);
    this.singOut = this.singOut.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setSearchVisibility = this.setSearchVisibility.bind(this);
    this.searching = this.searching.bind(this);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);

    this.state = {
      accounts: [],
      loadingItems: true,
      isSearchVisible: false,
      syncIntervalId: "",
      searchWords: "",
    };
  }

  setSearchVisibility = () => {
    if (this.state.isSearchVisible) {
      this.setState(
        {
          isSearchVisible: false,
          canSync: true,
          searchWords: "",
        },
        this.setItems
      );
    } else {
      this.setState(
        {
          isSearchVisible: true,
          canSync: false,
        },
        () => {
          document.getElementById("input_search").focus();
        }
      );
    }
  };

  searching(e) {
    const searchWords = e.target.value.toLowerCase();
    this.setState(
      {
        searchWords: searchWords,
      },
      () => this.setItems()
    );
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  refresh = () => {
    clearInterval(this.state.syncIntervalId);
    this.getData();
    this.initIntervals();
  };

  componentDidMount() {
    this.getData();
    this.initIntervals();
    document.title = "Accounts | Console - davidsimak.dev";
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getData() {
    this.setState({
      loadingItems: true,
    });

    this.getItems();
  }

  getItems() {
    accountService
      .getAll()
      .then((response) => {
        this.setState({
          accounts: [],
        });

        const accounts = response.data;

        this.setState({
          accounts: accounts,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  backgroundSync() {
    this.getItems();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data
    const accounts = this.state.accounts;

    // UI changes
    const loadingItems = this.state.loadingItems;
    const isSearchVisible = this.state.isSearchVisible;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="40"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Accounts</h1>
            </div>

            <nav>
              <ul>
                <li>
                  <div className="dropdown">
                    <button
                      title="Account Info"
                      className="action_button action_account_info"
                    />
                    <div className="dropdown_content dropdown_content_account_info">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.davidsimak.dev/account/"
                      >
                        Account Info
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={this.singOut}>Sign out</a>
                    </div>
                  </div>
                </li>
                <li className="menu_divider">|</li>
                <li>
                  <button
                    title="Refresh"
                    className="action_button action_refresh"
                    onClick={this.refresh}
                  />
                </li>
                <li>
                  <button
                    title="Search"
                    className="action_button action_search"
                    onClick={this.setSearchVisibility}
                  />
                </li>
              </ul>
            </nav>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                {isSearchVisible ? (
                  <div className="div_search">
                    <input
                      id="input_search"
                      placeholder="Type to search..."
                      className="input_search"
                      type="text"
                      onChange={this.searching}
                    />
                    <button
                      className="action_button action_close_search"
                      title="Close searchbar"
                      onClick={this.setSearchVisibility}
                    />
                  </div>
                ) : null}

                <table className="account_table table_top">
                  <tr>
                    <th className="account_table_td_name">Description</th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      Date of registration
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Active
                    </th>
                  </tr>
                </table>

                {accounts.map((account) => (
                  <Link className="row_website_item" to={account.id}>
                    <div className="row_website_item_div">
                      <table className="account_table">
                        <tr>
                          <td className="account_table_td_name">
                            <label className="log_item_message">
                              {account.email}
                            </label>

                            <label className="log_item_sessions">
                              <b>Account id:</b> {account.id}
                            </label>
                            <label className="log_item_sessions">
                              <b>Authentication id:</b>{" "}
                              {account.authenticationId}
                            </label>
                          </td>
                          <td style={{ width: "20%", textAlign: "center" }}>
                            {account.dateOfRegistration}
                          </td>
                          <td style={{ width: "10%" }}>
                            <div
                              className={
                                !account.blocked
                                  ? "info_status_true"
                                  : "info_status_false"
                              }
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
