import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import numberHelper from "../../helpers/number-helper";
import androidService from "../../services/android.service";
import PeriodToolbar from "../others/period-toolbar.component";
import PeriodToolbarReport from "../others/period-toolbar-report.component";

export default class AndroidAppDailyReports extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      appId: null,
      startDateTime: null,
      endDateTime: null,
      totalLogs: 0,
      totalLogsDebug: 0,
      totalLogsInfo: 0,
      totalLogsWarn: 0,
      totalLogsError: 0,
      totalLogsFatal: 0,
      totalCrashes: 0,
      newInstallations: 0,
      totalUsages: 0,
      items: [],
    };
  }

  componentDidMount() {
    this.setState({
      appId: this.props.appId,
    });
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  getBody() {
    return {
      id: this.state.appId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };
  }

  getData() {
    this.props.onLoadingStateChange(true);
    androidService
      .getDailyReport(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          totalLogs: responseData.totalLogs,
          totalLogsDebug: responseData.totalLogsDebug,
          totalLogsInfo: responseData.totalLogsInfo,
          totalLogsWarn: responseData.totalLogsWarn,
          totalLogsError: responseData.totalLogsError,
          totalLogsFatal: responseData.totalLogsFatal,
          totalCrashes: responseData.totalCrashes,
          newInstallations: responseData.newInstallations,
          totalUsages: responseData.totalUsages,
          items: responseData.items,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    const totalLogs = this.state.totalLogs;
    const totalLogsDebug = this.state.totalLogsDebug;
    const totalLogsInfo = this.state.totalLogsInfo;
    const totalLogsWarn = this.state.totalLogsWarn;
    const totalLogsError = this.state.totalLogsError;
    const totalLogsFatal = this.state.totalLogsFatal;
    const totalCrashes = this.state.totalCrashes;
    const newInstallations = this.state.newInstallations;
    const totalUsages = this.state.totalUsages;
    const items = this.state.items;

    items.forEach((item) => {
      item.Debug = item.totalLogsDebug;
      item.Info = item.totalLogsInfo;
      item.Warn = item.totalLogsWarn;
      item.Error = item.totalLogsError;
      item.Fatal = item.totalLogsFatal;
    });

    const NewInstallationsTooltips = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              New installations:{" "}
              {numberHelper.format(payload[0].payload.newInstallations)}
            </p>
          </div>
        );
      }

      return null;
    };

    const ActiveInstallationsTooltips = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Active installations:{" "}
              {numberHelper.format(payload[0].payload.activeInstallations)}
            </p>
          </div>
        );
      }

      return null;
    };

    const UsagesTooltips = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Active usages:{" "}
              {numberHelper.format(payload[0].payload.totalUsages)}
            </p>
          </div>
        );
      }

      return null;
    };

    const LogsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.totalLogs)}
              {payload[0].payload.totalLogs ? (
                <div>
                  <hr />
                  Info: {numberHelper.format(payload[0].payload.Info)}
                  <br />
                  Warn: {numberHelper.format(payload[0].payload.Warn)}
                  <br />
                  Error: {numberHelper.format(payload[0].payload.Error)}
                  <br />
                  Fatal: {numberHelper.format(payload[0].payload.Fatal)}
                  <br />
                  Debug: {numberHelper.format(payload[0].payload.Debug)}
                </div>
              ) : null}
            </p>
          </div>
        );
      }

      return null;
    };

    const CrashesTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.totalCrashes)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbarReport setPeriod={this.setPeriod} />

        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="android_app_statistics_title">
            New installations
          </label>
          <div className="android_app_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<NewInstallationsTooltips />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Bar dataKey="newInstallations" fill="#a5d6a7" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="android_app_statistics_value">
            Total: {newInstallations}
          </label>
        </div>
        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="android_app_statistics_title">
            Active installations
          </label>
          <div className="android_app_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<ActiveInstallationsTooltips />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Bar dataKey="activeInstallations" fill="#a5d6a7" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="android_app_statistics_title">
            Usage statistics
          </label>
          <div className="android_app_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<UsagesTooltips />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Bar dataKey="totalUsages" fill="#a5d6a7" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="android_app_statistics_value">
            Total: {totalUsages}
          </label>
        </div>
        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="android_app_statistics_title">
            Logs statistics
          </label>
          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<LogsTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="Debug" fill="#b0bec5" stackId="a" />
                <Bar dataKey="Info" fill="#a5d6a7" stackId="a" />
                <Bar dataKey="Warn" fill="#ffcc80" stackId="a" />
                <Bar dataKey="Error" fill="#ef9a9a" stackId="a" />
                <Bar dataKey="Fatal" fill="#f48fb1" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(totalLogs)}
            <br />
            Info: {numberHelper.format(totalLogsInfo)} | Warn:{" "}
            {numberHelper.format(totalLogsWarn)} | Error:{" "}
            {numberHelper.format(totalLogsError)} | Fatal:{" "}
            {numberHelper.format(totalLogsFatal)} | Debug:{" "}
            {numberHelper.format(totalLogsDebug)}
          </label>
        </div>
        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="android_app_statistics_title">
            Crash statistics
          </label>
          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<CrashesTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="totalCrashes" fill="#ef9a9a" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(totalCrashes)}
          </label>
        </div>
      </div>
    );
  }
}
