import http from "../helpers/http-common-reporting";

class ApiService {
  getAll() {
    return http.get(`/api/`);
  }

  getInfoHealth(id) {
    return http.get(`/api/api-info-health/${id}`);
  }

  getHistoricMetrics(data) {
    return http.post("/api/history-metrics", data);
  }

  getLogs(data) {
    return http.post("/api/logs", data);
  }

  getLog(id) {
    return http.get(`/api/log/${id}`);
  }

  getLogGroups(data) {
    return http.post("/api/log-groups", data);
  }

  getLogGroup(id) {
    return http.get(`/api/log-group/${id}`);
  }

  getHttpTraces(data) {
    return http.post("/api/http-traces", data);
  }

  getHttpTrace(id) {
    return http.get(`/api/http-trace/${id}`);
  }

  getHttpTraceGroups(data) {
    return http.post("/api/http-trace-groups", data);
  }

  getHttpTraceGroup(id) {
    return http.get(`/api/http-trace-group/${id}`);
  }

  getLogGroupHistory(data) {
    return http.post("/api/log-group-history", data);
  }

  getHttpTraceHistory(data) {
    return http.post("/api/http-trace-group-history", data);
  }

  getDailyReport(data) {
    return http.post("/api/daily-report", data);
  }
}

export default new ApiService();
