import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  XAxis,
  Area,
} from "recharts";
import memorySizeHelper from "../../helpers/memory-size-helper";
import graphHelper from "../../helpers/graph-helper";
import infrastructureService from "../../services/infrastructure.service";

export default class ServerCurrentUsageStatistics extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);

    this.state = {
      serverId: null,
      startDateTime: null,
      endDateTime: null,
      syncIntervalId: null,
      totalMemory: 0,
      usedMemory: 0,
      totalStorage: 0,
      usedStorage: 0,
      swapTotal: 0,
      swapUsed: 0,
      metricsUpdateDate: "",
      cpuAllActivePercent: 0,
      average1HourProcessorUsage: 0,
      average1HourMemoryUsage: 0,
      max1HourProcessorUsage: 0,
      max1HourMemoryUsage: 0,
      min1HourProcessorUsage: 0,
      min1HourMemoryUsage: 0,
      average24HourProcessorUsage: 0,
      average24HourMemoryUsage: 0,
      max24HourProcessorUsage: 0,
      max24HourMemoryUsage: 0,
      min24HourProcessorUsage: 0,
      min24HourMemoryUsage: 0,
      average1HourMemoryUsagePercentage: 0,
      max1HourMemoryUsagePercentage: 0,
      min1HourMemoryUsagePercentage: 0,
      average24HourMemoryUsagePercentage: 0,
      max24HourMemoryUsagePercentage: 0,
      min24HourMemoryUsagePercentage: 0,
      last2MinUsage: [],
    };
  }

  componentDidMount() {
    this.setState(
      {
        serverId: this.props.serverId,
      },
      () => this.getData()
    );
    this.initIntervals();
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  getStatistics() {
    infrastructureService
      .getCurrentUsage(this.state.serverId)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          totalMemory: responseData.totalMemory,
          usedMemory: responseData.usedMemory,
          totalStorage: responseData.totalStorage,
          usedStorage: responseData.usedStorage,
          swapTotal: responseData.swapTotal,
          swapUsed: responseData.swapUsed,
          metricsUpdateDate: responseData.metricsUpdateDate,
          cpuAllActivePercent: responseData.cpuAllActivePercent,
          average1HourProcessorUsage: responseData.average1HourProcessorUsage,
          average1HourMemoryUsage: responseData.average1HourMemoryUsage,
          max1HourProcessorUsage: responseData.max1HourProcessorUsage,
          max1HourMemoryUsage: responseData.max1HourMemoryUsage,
          min1HourProcessorUsage: responseData.min1HourProcessorUsage,
          min1HourMemoryUsage: responseData.min1HourMemoryUsage,
          average24HourProcessorUsage: responseData.average24HourProcessorUsage,
          average24HourMemoryUsage: responseData.average24HourMemoryUsage,
          max24HourProcessorUsage: responseData.max24HourProcessorUsage,
          max24HourMemoryUsage: responseData.max24HourMemoryUsage,
          min24HourProcessorUsage: responseData.min24HourProcessorUsage,
          min24HourMemoryUsage: responseData.min24HourMemoryUsage,
          average1HourMemoryUsagePercentage:
            responseData.average1HourMemoryUsagePercentage,
          max1HourMemoryUsagePercentage:
            responseData.max1HourMemoryUsagePercentage,
          min1HourMemoryUsagePercentage:
            responseData.min1HourMemoryUsagePercentage,
          average24HourMemoryUsagePercentage:
            responseData.average24HourMemoryUsagePercentage,
          max24HourMemoryUsagePercentage:
            responseData.max24HourMemoryUsagePercentage,
          min24HourMemoryUsagePercentage:
            responseData.min24HourMemoryUsagePercentage,
          last2MinUsage: responseData.last2MinUsage,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getStatistics();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 10000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data

    const totalMemory = this.state.totalMemory;
    const usedMemory = this.state.usedMemory;
    const totalStorage = this.state.totalStorage;
    const usedStorage = this.state.usedStorage;
    const swapTotal = this.state.swapTotal;
    const swapUsed = this.state.swapUsed;
    const metricsUpdateDate = this.state.metricsUpdateDate;
    const cpuAllActivePercent = this.state.cpuAllActivePercent;
    const average1HourProcessorUsage = this.state.average1HourProcessorUsage;
    const average1HourMemoryUsage = this.state.average1HourMemoryUsage;
    const max1HourProcessorUsage = this.state.max1HourProcessorUsage;
    const max1HourMemoryUsage = this.state.max1HourMemoryUsage;
    const min1HourProcessorUsage = this.state.min1HourProcessorUsage;
    const min1HourMemoryUsage = this.state.min1HourMemoryUsage;
    const average24HourProcessorUsage = this.state.average24HourProcessorUsage;
    const average24HourMemoryUsage = this.state.average24HourMemoryUsage;
    const max24HourProcessorUsage = this.state.max24HourProcessorUsage;
    const max24HourMemoryUsage = this.state.max24HourMemoryUsage;
    const min24HourProcessorUsage = this.state.min24HourProcessorUsage;
    const min24HourMemoryUsage = this.state.min24HourMemoryUsage;
    const average1HourMemoryUsagePercentage =
      this.state.average1HourMemoryUsagePercentage;
    const max1HourMemoryUsagePercentage =
      this.state.max1HourMemoryUsagePercentage;
    const min1HourMemoryUsagePercentage =
      this.state.min1HourMemoryUsagePercentage;
    const average24HourMemoryUsagePercentage =
      this.state.average24HourMemoryUsagePercentage;
    const max24HourMemoryUsagePercentage =
      this.state.max24HourMemoryUsagePercentage;
    const min24HourMemoryUsagePercentage =
      this.state.min24HourMemoryUsagePercentage;
    const last2MinUsage = this.state.last2MinUsage;

    const memoryData = [
      {
        value: Math.round(((usedMemory * 100) / totalMemory) * 100) / 100,
      },
      {
        value: 100 - Math.round(((usedMemory * 100) / totalMemory) * 100) / 100,
      },
    ];
    const storageData = [
      {
        value: Math.round(((usedStorage * 100) / totalStorage) * 100) / 100,
      },
      {
        value:
          100 - Math.round(((usedStorage * 100) / totalStorage) * 100) / 100,
      },
    ];
    const swapData = [
      {
        value: Math.round(((swapUsed * 100) / swapTotal) * 100) / 100,
      },
      {
        value: 100 - Math.round(((swapUsed * 100) / swapTotal) * 100) / 100,
      },
    ];
    const cpuAllActiveData = [
      {
        value: cpuAllActivePercent,
      },
      {
        value: 100 - cpuAllActivePercent,
      },
    ];

    const ProcessorTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.time}</p>
            <p className="custom-tooltip-desc">
              <b>Active:</b> {payload[0].payload.cpuAllActivePercent}%
            </p>
          </div>
        );
      }

      return null;
    };

    const MemoryTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.time}</p>
            <p className="custom-tooltip-desc">
              <b>Used:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(
                payload[0].payload.usedMemory
              )}
              <br />
              <b>Total:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(
                payload[0].payload.totalMemory
              )}
            </p>
          </div>
        );
      }

      return null;
    };

    const SwapTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.time}</p>
            <p className="custom-tooltip-desc">
              <b>Used:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(
                payload[0].payload.swapUsed
              )}
              <br />
              <b>Total:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(
                payload[0].payload.swapTotal
              )}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <div className="server_current_usage_div">
          <label className="server_div_title">
            Current usage from <b>{metricsUpdateDate}</b>
          </label>
          <div className="server_current_usage_div1">
            <div className="server_current_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={cpuAllActiveData}
                    dataKey="value"
                    cx="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="#82ca9d"
                    startAngle={0}
                    endAngle={180}
                  >
                    {cpuAllActiveData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0
                            ? graphHelper.getColor(cpuAllActivePercent)
                            : "#ECEFF1"
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <label className="server_current_usage_count">
              {cpuAllActivePercent}%
            </label>
            <label className="server_current_usage_count_small"></label>
            <label className="server_current_usage_label">Processor</label>
          </div>
          <div className="server_current_usage_div1">
            <div className="server_current_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={memoryData}
                    dataKey="value"
                    cx="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="#82ca9d"
                    startAngle={0}
                    endAngle={180}
                  >
                    {memoryData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0
                            ? graphHelper.getColor(
                                (usedMemory * 100) / totalMemory
                              )
                            : "#ECEFF1"
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <label className="server_current_usage_count">
              {Math.round(((usedMemory * 100) / totalMemory) * 100) / 100}%
            </label>
            <label className="server_current_usage_count_small">
              {" "}
              {memorySizeHelper.convertFromMegaBytes(usedMemory) +
                " / " +
                memorySizeHelper.convertFromMegaBytes(totalMemory)}
            </label>
            <label className="server_current_usage_label">Memory</label>
          </div>
          <div className="server_current_usage_div1">
            <div className="server_current_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={swapData}
                    dataKey="value"
                    cx="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="#82ca9d"
                    startAngle={0}
                    endAngle={180}
                  >
                    {swapData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0
                            ? graphHelper.getColor((swapUsed * 100) / swapTotal)
                            : "#ECEFF1"
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <label className="server_current_usage_count">
              {Math.round(((swapUsed * 100) / swapTotal) * 100) / 100}%
            </label>
            <label className="server_current_usage_count_small">
              {" "}
              {memorySizeHelper.convertFromMegaBytes(swapUsed) +
                " / " +
                memorySizeHelper.convertFromMegaBytes(swapTotal)}
            </label>
            <label className="server_current_usage_label">Swap</label>
          </div>
          <div className="server_current_usage_div1">
            <div className="server_current_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={storageData}
                    dataKey="value"
                    cx="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="#82ca9d"
                    startAngle={0}
                    endAngle={180}
                  >
                    {storageData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0
                            ? graphHelper.getColor(
                                (usedStorage * 100) / totalStorage
                              )
                            : "#ECEFF1"
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <label className="server_current_usage_count">
              {Math.round(((usedStorage * 100) / totalStorage) * 100) / 100}%
            </label>
            <label className="server_current_usage_count_small">
              {" "}
              {memorySizeHelper.convertFromMegaBytes(usedStorage) +
                " / " +
                memorySizeHelper.convertFromMegaBytes(totalStorage)}
            </label>
            <label className="server_current_usage_label">Storage</label>
          </div>
        </div>

        <label className="server_usage_graph_title">Last 2 min</label>

        <div className="server_usage_graph_div">
          <div className="server_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={last2MinUsage}>
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  tickFormatter={graphHelper.formatPercent}
                />
                <XAxis dataKey="time" />
                <Tooltip content={<ProcessorTooltip />} />
                <Area
                  type="monotone"
                  dataKey="cpuAllActivePercent"
                  stroke="#80cbc4"
                  fill="#80cbc4"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <label className="server_usage_graph_label">Processor</label>
        </div>

        <div className="server_usage_graph_div">
          <div className="server_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={last2MinUsage}>
                <YAxis
                  type="number"
                  domain={[0, totalMemory]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <XAxis dataKey="time" />
                <Tooltip content={<MemoryTooltip />} />
                <Area
                  type="monotone"
                  dataKey="usedMemory"
                  stroke="#ce93d8"
                  fill="#ce93d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <label className="server_usage_graph_label">Memory</label>
        </div>

        <div className="server_usage_graph_div">
          <div className="server_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={last2MinUsage}>
                <YAxis
                  type="number"
                  domain={[0, swapTotal]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <XAxis dataKey="time" />
                <Tooltip content={<SwapTooltip />} />
                <Area
                  type="monotone"
                  dataKey="swapUsed"
                  stroke="#ce93d8"
                  fill="#ce93d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <label className="server_usage_graph_label">Swap</label>
        </div>

        <div className="server_last_usage_div">
          <label className="server_last_title">Last hour</label>
          <div className="server_last_usage_div1">
            <label className="server_last_usage_title">Max</label>
            <label className="server_last_usage_value">
              {max1HourProcessorUsage}%
            </label>
            <label className="server_last_usage_title">Avg</label>
            <label className="server_last_usage_value">
              {average1HourProcessorUsage}%
            </label>
            <label className="server_last_usage_title">Min</label>
            <label className="server_last_usage_value">
              {min1HourProcessorUsage}%
            </label>
            <label className="server_last_usage_label">Processor</label>
          </div>
          <div className="server_last_usage_div1">
            <label className="server_last_usage_title">Max</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(max1HourMemoryUsage) +
                " (" +
                max1HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_title">Avg</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(average1HourMemoryUsage) +
                " (" +
                average1HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_title">Min</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(min1HourMemoryUsage) +
                " (" +
                min1HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_label">Memory</label>
          </div>
        </div>

        <div className="server_last_usage_div">
          <label className="server_last_title">Last 24 hours</label>
          <div className="server_last_usage_div1">
            <label className="server_last_usage_title">Max</label>
            <label className="server_last_usage_value">
              {max24HourProcessorUsage}%
            </label>
            <label className="server_last_usage_title">Avg</label>
            <label className="server_last_usage_value">
              {average24HourProcessorUsage}%
            </label>
            <label className="server_last_usage_title">Min</label>
            <label className="server_last_usage_value">
              {min24HourProcessorUsage}%
            </label>
            <label className="server_last_usage_label">Processor</label>
          </div>
          <div className="server_last_usage_div1">
            <label className="server_last_usage_title">Max</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(max24HourMemoryUsage) +
                " (" +
                max24HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_title">Avg</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(average24HourMemoryUsage) +
                " (" +
                average24HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_title">Min</label>
            <label className="server_last_usage_value">
              {memorySizeHelper.convertFromMegaBytes(min24HourMemoryUsage) +
                " (" +
                min24HourMemoryUsagePercentage +
                "%)"}
            </label>
            <label className="server_last_usage_label">Memory</label>
          </div>
        </div>
      </div>
    );
  }
}
