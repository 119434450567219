import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import numberHelper from "../../helpers/number-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import { Link } from "react-router-dom";
import androidService from "../../services/android.service";

export default class AndroidLogsList extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.search = this.search.bind(this);
    this.cleanSearch = this.cleanSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      appId: null,
      startDateTime: null,
      endDateTime: null,
      logs: [],
      metrics: [],
      info: 0,
      warn: 0,
      error: 0,
      fatal: 0,
      debug: 0,
      total: 0,
      maxLogPages: 1,
      currentLogPage: 1,
      search: "",
    };
  }

  cleanSearch() {
    this.setState(
      {
        search: "",
      },
      () => this.getData()
    );
  }

  search() {
    this.getData();
  }

  componentDidMount() {
    this.setState({
      appId: this.props.appId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  onSearchChange(event) {
    this.setState({
      search: event.target.value,
    });
  }

  getBody() {
    const body = {
      id: this.state.appId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentLogPage - 1) * 50,
    };

    const search = this.state.search;

    if (search.length === 0) {
      return body;
    }

    const searchArray = search.includes('"|') ? search.split('"|') : [search];

    searchArray.forEach((searchItem) => {
      if (!searchItem.includes(":")) {
        return;
      }

      const searchItemType = searchItem.split(':"')[0];
      const searchItemValue = searchItem
        .replace(searchItemType + ':"', "")
        .replace(/"$/, "");

      body[searchItemType] = searchItemValue;
    });
    return body;
  }

  getStatistics() {
    androidService
      .getLogs(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          logs: responseData.logs,
          metrics: responseData.metrics,
          info: responseData.info,
          warn: responseData.warn,
          error: responseData.error,
          fatal: responseData.fatal,
          debug: responseData.debug,
          total: responseData.total,
          maxLogPages: Math.ceil(responseData.total / 50),
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  pageBack() {
    const newCurrentLogPage = this.state.currentLogPage - 1;
    if (newCurrentLogPage === 0) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  pageNext() {
    const newCurrentLogPage = this.state.currentLogPage + 1;
    if (newCurrentLogPage > this.state.maxLogPages) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  render() {
    const logs = this.state.logs;
    const metrics = this.state.metrics;
    const info = this.state.info;
    const warn = this.state.warn;
    const error = this.state.error;
    const fatal = this.state.fatal;
    const debug = this.state.debug;
    const total = this.state.total;
    const currnetPage = this.state.currentLogPage;
    const maxPages = this.state.maxLogPages;
    const search = this.state.search;

    const LogsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.Total)}
              {payload[0].payload.Info ? (
                <div>
                  <hr />
                  Info: {numberHelper.format(payload[0].payload.Info)}
                  <br />
                  Warn: {numberHelper.format(payload[0].payload.Warn)}
                  <br />
                  Error: {numberHelper.format(payload[0].payload.Error)}
                  <br />
                  Fatal: {numberHelper.format(payload[0].payload.Fatal)}
                  <br />
                  Debug: {numberHelper.format(payload[0].payload.Debug)}
                </div>
              ) : null}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <div className="log_search_container">
          <input
            type="text"
            onChange={this.onSearchChange}
            value={search}
            placeholder="Search"
            className="log_search_input"
          />
          <button
            onClick={this.cleanSearch}
            className="action_button action_clean_search"
          />
          <button
            onClick={this.search}
            className="action_button action_search_logs"
          />
        </div>
        <PeriodToolbar setPeriod={this.setPeriod} />
        <div className="api_logs_statistics_container">
          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={metrics}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<LogsTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="Debug" fill="#b0bec5" stackId="a" />
                <Bar dataKey="Info" fill="#a5d6a7" stackId="a" />
                <Bar dataKey="Warn" fill="#ffcc80" stackId="a" />
                <Bar dataKey="Error" fill="#ef9a9a" stackId="a" />
                <Bar dataKey="Fatal" fill="#f48fb1" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(total)}
            <br />
            Info: {numberHelper.format(info)} | Warn:{" "}
            {numberHelper.format(warn)} | Error: {numberHelper.format(error)} |
            Fatal: {numberHelper.format(fatal)} | Debug:{" "}
            {numberHelper.format(debug)}
          </label>
        </div>

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>

        <table className="logs_table logs_table_top">
          <tr>
            <th className="android_logs_table_small_column">Type</th>
            <th className="android_logs_table_description">Description</th>
            <th className="android_logs_table_column">Date</th>
            <th className="android_logs_table_small_column">Version</th>
            <th className="android_logs_table_small_column">Android</th>
          </tr>
        </table>
        {logs.map((log) => (
          <Link className="row_website_item" to={"log/" + log.id}>
            <div className="row_logs_item_div">
              <table className="logs_table">
                <tr>
                  <td className="android_logs_table_small_column">
                    {log.level}
                  </td>
                  <td className="android_logs_table_description">
                    {log.classPackage || log.methodName ? (
                      <label className="log_item_class_package_method_name">
                        {log.classPackage ? log.classPackage : null}
                        {log.methodName ? ` in ${log.methodName}` : null}
                      </label>
                    ) : null}

                    <label className="log_item_message">{log.message}</label>

                    {log.appUsageId ? (
                      <label className="log_item_sessions">
                        <b>App usage:</b> {log.appUsageId}
                      </label>
                    ) : null}
                    {log.userSession ? (
                      <label className="log_item_sessions">
                        <b>User session:</b> {log.userSession}
                      </label>
                    ) : null}
                    {log.installationId ? (
                      <label className="log_item_sessions">
                        <b>Installation ID:</b> {log.installationId}
                      </label>
                    ) : null}
                  </td>
                  <td className="android_logs_table_column">
                    {log.reportDate}
                  </td>
                  <td className="android_logs_table_small_column">
                    {log.appVersionNameCode}
                  </td>
                  <td className="android_logs_table_small_column">
                    {log.androidVersion}
                  </td>
                </tr>
              </table>
            </div>
          </Link>
        ))}

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>
      </div>
    );
  }
}
