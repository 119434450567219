import http from "../helpers/http-common-reporting";

class AndroidService {
  getAll() {
    return http.get(`/android/`);
  }

  getInfo(id) {
    return http.get(`/android/app-info/${id}`);
  }

  getStatistics(data) {
    return http.post("/android/statistics/", data);
  }

  getLogs(data) {
    return http.post("/android/logs", data);
  }

  getLog(id) {
    return http.get(`/android/log/${id}`);
  }

  getLogGroups(data) {
    return http.post("/android/log-groups", data);
  }

  getLogGroup(id) {
    return http.get(`/android/log-group/${id}`);
  }

  getLogGroupHistory(data) {
    return http.post("/android/log-group-history", data);
  }

  getDailyReport(data) {
    return http.post("/android/daily-report", data);
  }
}

export default new AndroidService();
