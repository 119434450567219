import React, { Component } from "react";
import dateHelper from "../../helpers/date-helper";

export default class PeriodToolbarReport extends Component {
  constructor(props) {
    super(props);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectPeriodEvent = this.selectPeriodEvent.bind(this);
    this.closeCustomPeriodDialog = this.closeCustomPeriodDialog.bind(this);
    this.closeCustomPeriodDialogAndSetValues =
      this.closeCustomPeriodDialogAndSetValues.bind(this);
    this.refresh = this.refresh.bind(this);

    this.state = {
      startDateTime: null,
      endDateTime: null,
      isCustomPeriodDialogVisible: false,
      selectedPeriodOption: "7D",
      tempStartDateTime: null,
      tempEndDateTime: null,
    };
  }

  componentDidMount() {
    const startDate = dateHelper.getUTCDateTime();
    startDate.setUTCDate(startDate.getUTCDate() - 8);
    const endDate = dateHelper.getUTCDateTime();
    endDate.setUTCDate(endDate.getUTCDate() - 1);

    this.setState(
      {
        startDateTime: startDate,
        endDateTime: endDate,
      },
      () => this.setPeriod()
    );
  }

  selectPeriodEvent(event) {
    this.selectPeriod(event.target.value);
  }

  refresh() {
    this.selectPeriod(this.state.selectedPeriodOption);
  }

  selectPeriod(value) {
    if (value === "CUSTOM") {
      this.setState({
        selectedPeriodOption: value,
        isCustomPeriodDialogVisible: true,
      });
      return;
    }

    var endDateTime = dateHelper.getUTCDateTime();
    endDateTime.setUTCDate(endDateTime.getUTCDate() - 1);
    var startDateTime = new Date(endDateTime);

    switch (value) {
      case "7D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 7);
        break;
      default:
      case "14D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 14);
        break;
      case "30D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 30);
        break;
      case "60D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 60);
        break;
      case "90D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 90);
        break;
      case "180D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 180);
        break;
      case "360D":
        startDateTime.setUTCDate(startDateTime.getUTCDate() - 360);
        break;
      case "THIS_WEEK":
        startDateTime = dateHelper.getMonday();
        endDateTime = new Date(startDateTime);
        endDateTime.setUTCDate(endDateTime.getUTCDate() + 7);
        break;
      case "LAST_WEEK":
        endDateTime = dateHelper.getMonday();
        startDateTime = new Date(endDateTime);
        startDateTime.setUTCDate(endDateTime.getUTCDate() - 7);
        break;
      case "THIS_MONTH":
        startDateTime = dateHelper.getFirstDayOfMonth();
        endDateTime = new Date(startDateTime);
        endDateTime.setUTCMonth(endDateTime.getUTCMonth() + 1);
        break;
      case "LAST_MONTH":
        endDateTime = dateHelper.getFirstDayOfMonth();
        startDateTime = new Date(endDateTime);
        startDateTime.setUTCMonth(startDateTime.getUTCMonth() - 1);
        break;
      case "THIS_QUARTER":
        startDateTime = dateHelper.getFirstDayOfQuarter();
        endDateTime = new Date(startDateTime);
        endDateTime.setUTCMonth(endDateTime.getUTCMonth() + 3);
        break;
      case "LAST_QUARTER":
        endDateTime = dateHelper.getFirstDayOfQuarter();
        startDateTime = new Date(endDateTime);
        startDateTime.setUTCMonth(startDateTime.getUTCMonth() - 3);
        break;
      case "THIS_YEAR":
        startDateTime = dateHelper.getFirstDayOfYear();
        endDateTime = new Date(startDateTime);
        endDateTime.setUTCFullYear(endDateTime.getUTCFullYear() + 1);
        break;
      case "LAST_YEAR":
        endDateTime = dateHelper.getFirstDayOfYear();
        startDateTime = new Date(endDateTime);
        startDateTime.setUTCFullYear(startDateTime.getUTCFullYear() - 1);
        break;
    }

    this.setState(
      {
        selectedPeriodOption: value,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.setPeriod()
    );
  }

  setPeriod() {
    const startDateTime = this.state.startDateTime;
    const endDateTime = this.state.endDateTime;
    this.props.setPeriod(
      dateHelper.convertDateToString(startDateTime),
      dateHelper.convertDateToString(endDateTime)
    );
  }

  setStartDate(event) {
    this.setState({
      tempStartDateTime: new Date(event.target.value),
    });
  }

  setEndDate(event) {
    this.setState({
      tempEndDateTime: new Date(event.target.value),
    });
  }

  closeCustomPeriodDialog() {
    this.setState({
      isCustomPeriodDialogVisible: false,
    });
  }

  closeCustomPeriodDialogAndSetValues() {
    const startDateTime = this.state.tempStartDateTime;
    const endDateTime = this.state.tempEndDateTime;

    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        isCustomPeriodDialogVisible: false,
      },
      () => this.setPeriod()
    );
  }

  render() {
    // Data
    const startDateTime = this.state.startDateTime;
    const endDateTime = this.state.endDateTime;
    const isCustomPeriodDialogVisible = this.state.isCustomPeriodDialogVisible;

    return (
      <div className="period-toolbar">
        <div className="period_title_div">
          <label className="period_title_date">
            {startDateTime !== null && endDateTime !== null
              ? dateHelper.getTimePeriod(startDateTime, endDateTime)
              : null}
          </label>
        </div>
        <div className="period_selector">
          <button
            className="action_button action_refresh button_right"
            title="Refresh"
            onClick={this.refresh}
          />
          <select
            className="period_select"
            name="periods"
            id="periods"
            value={this.state.selectedPeriodOption}
            onChange={this.selectPeriodEvent}
          >
            <option value="7D">Last 7 days</option>
            <option value="14D">Last 14 days</option>
            <option value="30D">Last 30 days</option>
            <option value="60D">Last 60 days</option>
            <option value="90D">Last 90 days</option>
            <option value="180D">Last 180 days</option>
            <option value="360D">Last 360 days</option>
            <option value="THIS_WEEK">This week</option>
            <option value="LAST_WEEK">Last week</option>
            <option value="THIS_MONTH">This month</option>
            <option value="LAST_MONTH">Last month</option>
            <option value="THIS_QUARTER">This quarter</option>
            <option value="LAST_QUARTER">Last quarter</option>
            <option value="THIS_YEAR">This year</option>
            <option value="LAST_YEAR">Last year</option>
            <option value="CUSTOM">Custom</option>
          </select>
        </div>

        {isCustomPeriodDialogVisible ? (
          <div className="custom_period_dialog">
            <div className="custom_period_div_inpuit">
              <label className="custom_period_dialog_label">From</label>
              <input
                type="datetime-local"
                className="custom_period_dialog_input"
                onChange={this.setStartDate}
              />
            </div>
            <div className="custom_period_div_inpuit">
              <label className="custom_period_dialog_label">To</label>
              <input
                type="datetime-local"
                className="custom_period_dialog_input"
                onChange={this.setEndDate}
              />
            </div>
            <div className="custom_period_dialog_div_buttons">
              <button
                className="custom_period_dialog_button_cancel"
                onClick={this.closeCustomPeriodDialog}
              >
                Cancel
              </button>
              <button
                className="custom_period_dialog_button_set_period"
                onClick={this.closeCustomPeriodDialogAndSetValues}
              >
                Set period
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
