class DateHelper {
  convertDateToString(date) {
    var dateUTC = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(dateUTC).toISOString();
  }
  getTimePeriod(startDate, endDate) {
    const startDateValue =
      this.getShortMonthDisplayName(startDate) + " " + startDate.getUTCDate();
    const startTimeValue =
      this.addZerosToNumber(startDate.getUTCHours()) +
      ":" +
      this.addZerosToNumber(startDate.getUTCMinutes()) +
      (startDate.getUTCSeconds() !== 0
        ? ":" + this.addZerosToNumber(startDate.getUTCSeconds())
        : "");
    const startYearValue = startDate.getUTCFullYear().toString();

    const endDateValue =
      this.getShortMonthDisplayName(endDate) + " " + endDate.getUTCDate();
    const endTimeValue =
      this.addZerosToNumber(endDate.getUTCHours()) +
      ":" +
      this.addZerosToNumber(endDate.getUTCMinutes()) +
      (endDate.getUTCSeconds() !== 0
        ? ":" + this.addZerosToNumber(endDate.getUTCSeconds())
        : "");
    const endYearValue = endDate.getUTCFullYear().toString();

    const includeYear = startDate.getUTCFullYear() !== endDate.getUTCFullYear();

    return (
      startDateValue +
      (includeYear ? ", " + startYearValue : "") +
      " " +
      startTimeValue +
      " - " +
      endDateValue +
      (includeYear ? ", " + endYearValue : "") +
      " " +
      endTimeValue
    );
  }

  getShortMonthDisplayName(date) {
    return date.toLocaleString("en-US", { month: "short" });
  }

  addZerosToNumber(number) {
    var num = number.toString();
    if (num.length === 2) {
      return num;
    }

    return "0" + num;
  }

  getUTCDateTime() {
    var date = new Date();

    var utcDate = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );

    utcDate.setUTCMinutes(0);
    utcDate.setUTCSeconds(0);
    utcDate.setUTCHours(0);
    utcDate.setUTCDate(utcDate.getUTCDate() + 1);

    return utcDate;
  }

  getMonday() {
    var d = this.getUTCDateTime();
    d.setUTCDate(d.getUTCDate() - 1);
    var day = d.getDay(),
      diff = d.getUTCDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setUTCDate(diff));
  }

  getFirstDayOfMonth() {
    var date = this.getUTCDateTime();
    date.setUTCDate(date.getUTCDate() - 1);
    date.setUTCDate(1);
    return date;
  }

  getFirstDayOfQuarter() {
    var date = this.getUTCDateTime();
    date.setUTCDate(date.getUTCDate() - 1);
    date.setUTCDate(1);
    switch (date.getUTCMonth()) {
      case 0:
      case 1:
      case 2:
        date.setUTCMonth(0);
        break;
      case 3:
      case 4:
      case 5:
        date.setUTCMonth(3);
        break;
      case 6:
      case 7:
      case 8:
        date.setUTCMonth(6);
        break;
      case 9:
      case 10:
      case 11:
        date.setUTCMonth(9);
        break;
    }
    console.log("Quarter date: " + date);
    return date;
  }

  getFirstDayOfYear() {
    var date = this.getUTCDateTime();
    date.setUTCDate(date.getUTCDate() - 1);
    date.setUTCDate(1);
    date.setUTCMonth(0);
    return date;
  }
}

export default new DateHelper();
