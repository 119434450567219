import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import AndroidLogsList from "./android-app-logs-list.component";
import AndroidAppLogGroupsList from "./android-app-loggroups-list.component";
import AndroidAppStatistics from "./android-app-statistics.component";
import AndroidAppInfo from "./android-app-info.component";
import AndroidCrashesList from "./android-app-crashes-list.component";
import AndroidAppCrashGroupsList from "./android-app-crash-groups-list.component";
import AndroidAppDailyReports from "./android-app-daily-reports.component";

export default class AndroidAppComponent extends Component {
  constructor(props) {
    super(props);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.setBasicInfo = this.setBasicInfo.bind(this);

    this.state = {
      id: null,
      name: null,
      loadingItems: true,
      tabKey: "",
    };
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var appId = pathSplit[pathSplit.length - 1];

    this.setState({
      id: appId,
      tabKey: "INFO",
    });
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  setBasicInfo(name) {
    var tabKey = "";

    if (window.location.search !== "") {
      tabKey = window.location.search.replace("?tabKey=", "");
    } else {
      tabKey = "INFO";
    }

    this.setState({
      name: name,
      tabKey: tabKey,
    });

    document.title = name + "(Android app) | Console - davidsimak.dev";
  }

  render() {
    // Data
    const id = this.state.id;
    const name = this.state.name;
    const tabKey = this.state.tabKey;

    // UI changes
    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/android-apps">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>{name} (Android app)</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                <div className="api_tabs">
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "INFO" ? "website_tabs_button_selected" : "")
                    }
                    style={{ width: "calc(15% - 30px)" }}
                    onClick={() => this.setTabKey("INFO")}
                  >
                    Info
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "STATISTICS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("STATISTICS")}
                  >
                    Statistics
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "LOGS" ? "website_tabs_button_selected" : "")
                    }
                    onClick={() => this.setTabKey("LOGS")}
                  >
                    Logs
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "LOG_GROUPS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("LOG_GROUPS")}
                  >
                    Log groups
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "CRASHES"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("CRASHES")}
                  >
                    Crashes
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "CRASH_GROUPS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("CRASH_GROUPS")}
                  >
                    Crash groups
                  </button>
                  <button
                    className={
                      "android_app_tabs_button " +
                      (tabKey === "REPORTS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    style={{ width: "calc(15% - 30px)" }}
                    onClick={() => this.setTabKey("REPORTS")}
                  >
                    Daily reports
                  </button>
                </div>

                {tabKey === "INFO" ? (
                  <AndroidAppInfo
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                    setBasicInfo={this.setBasicInfo}
                  />
                ) : null}

                {tabKey === "STATISTICS" ? (
                  <AndroidAppStatistics
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "LOGS" ? (
                  <AndroidLogsList
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "LOG_GROUPS" ? (
                  <AndroidAppLogGroupsList
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "CRASHES" ? (
                  <AndroidCrashesList
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "CRASH_GROUPS" ? (
                  <AndroidAppCrashGroupsList
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "REPORTS" ? (
                  <AndroidAppDailyReports
                    appId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
