import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import apiService from "../../services/api.service";

export default class ApiLogDetail extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.state = {
      apiVersion: "",
      stackTrace: "",
      eventDate: "",
      level: "",
      message: "",
      methodName: "",
      methodParameters: "",
      classPackage: "",
      apiUsageId: "",
      userSession: "",
      loadingItems: false,
      parsedMethodParameters: "",
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var logId = pathSplit[pathSplit.length - 1];
    var apiId = pathSplit[pathSplit.length - 3];

    document.title = "Log detail | Console - davidsimak.dev";

    this.setState(
      {
        apiId: apiId,
        logId: logId,
        loadingItems: true,
      },
      () => this.getData()
    );
  }

  getData() {
    apiService
      .getLog(this.state.logId)
      .then((response) => {
        console.log(response);
        const responseData = response.data;
        console.log(responseData);
        const methodParameters = responseData.methodParameters;
        console.log(methodParameters);
        var parsedMethodParameters = "";
        if (methodParameters) {
          var json = JSON.parse(methodParameters);
          Object.keys(json).forEach((key) => {
            parsedMethodParameters +=
              "<b>" + key + "</b>: " + json[key] + "<br/>";
          });
        }

        console.log(parsedMethodParameters);

        var stackTrace = responseData.stackTrace;
        if (stackTrace) {
          stackTrace = stackTrace
            .replaceAll("\n", "<br />")
            .replaceAll("Caused by:", "<br />Caused by:");
        }

        this.setState({
          apiVersion: responseData.apiVersion,
          stackTrace: stackTrace,
          eventDate: responseData.eventDate,
          level: responseData.level,
          message: responseData.message,
          methodName: responseData.methodName,
          methodParameters: responseData.methodParameters,
          classPackage: responseData.classPackage,
          apiUsageId: responseData.apiUsageId,
          userSession: responseData.userSession,
          loadingItems: false,
          parsedMethodParameters: parsedMethodParameters,
        });

        console.log("set state");

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data

    const apiVersion = this.state.apiVersion;
    const stackTrace = this.state.stackTrace;
    const eventDate = this.state.eventDate;
    const level = this.state.level;
    const message = this.state.message;
    const methodName = this.state.methodName;
    const methodParameters = this.state.methodParameters;
    const classPackage = this.state.classPackage;
    const apiUsageId = this.state.apiUsageId;
    const userSession = this.state.userSession;
    const parsedMethodParameters = this.state.parsedMethodParameters;

    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/apis/${this.state.apiId}?tabKey=LOGS`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Log detail</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="log_detail_container">
              <label className="log_detail_class_method_name">
                {classPackage}
                {methodName ? ` in ${methodName}` : null}
              </label>
              <label className="log_detail_message">{message}</label>
              <hr />
              <div className="log_detail_basic_info_container">
                <label className="log_detail_basic_info_title">Level</label>
                <label className="log_detail_basic_info_value">{level}</label>
              </div>
              <div className="log_detail_basic_info_container">
                <label className="log_detail_basic_info_title">
                  Event date
                </label>
                <label className="log_detail_basic_info_value">
                  {eventDate}
                </label>
              </div>
              <div className="log_detail_basic_info_container">
                <label className="log_detail_basic_info_title">
                  API version
                </label>
                <label className="log_detail_basic_info_value">
                  {apiVersion}
                </label>
              </div>
              <hr />
              {methodParameters ? (
                <div>
                  <label className="log_detail_title">Method parameters</label>
                  <div
                    className="log_detail_value"
                    dangerouslySetInnerHTML={{ __html: parsedMethodParameters }}
                  />
                </div>
              ) : null}

              {stackTrace ? (
                <div>
                  <label className="log_detail_title">Stack trace</label>
                  <div
                    className="log_detail_value"
                    dangerouslySetInnerHTML={{ __html: stackTrace }}
                  />
                </div>
              ) : null}

              {apiUsageId ? (
                <div>
                  <label className="log_detail_title">API usage</label>
                  <label className="log_detail_value">{apiUsageId}</label>
                </div>
              ) : null}

              {userSession ? (
                <div>
                  <label className="log_detail_title">User session</label>
                  <label className="log_detail_value">{userSession}</label>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
