import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import androidService from "../../services/android.service";

export default class AndroidAppLogDetail extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.state = {
      androidVersion: "",
      appVersionNameCode: "",
      stackTrace: "",
      reportDate: "",
      level: "",
      message: "",
      methodName: "",
      methodParameters: "",
      classPackage: "",
      appUsageId: "",
      userSession: "",
      loadingItems: false,
      parsedMethodParameters: "",
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var logId = pathSplit[pathSplit.length - 1];
    var appId = pathSplit[pathSplit.length - 3];

    document.title = "Log detail | Console - davidsimak.dev";

    this.setState(
      {
        appId: appId,
        logId: logId,
        loadingItems: true,
      },
      () => this.getData()
    );
  }

  getData() {
    androidService
      .getLog(this.state.logId)
      .then((response) => {
        const responseData = response.data;
        const methodParameters = responseData.methodParameters;
        var parsedMethodParameters = "";

        if (methodParameters) {
          var json = JSON.parse(methodParameters);
          Object.keys(json).forEach((key) => {
            parsedMethodParameters +=
              "<b>" + key + "</b>: " + json[key] + "<br/>";
          });
        }

        this.setState({
          androidVersion: responseData.androidVersion,
          appVersionNameCode: responseData.appVersionNameCode,
          stackTrace: responseData.stackTrace
            .replaceAll("\n", "<br />")
            .replaceAll("Caused by:", "<br />Caused by:"),
          reportDate: responseData.reportDate,
          level: responseData.level,
          message: responseData.message,
          methodName: responseData.methodName,
          methodParameters: responseData.methodParameters,
          classPackage: responseData.classPackage,
          appUsageId: responseData.appUsageId,
          userSession: responseData.userSession,
          loadingItems: false,
          parsedMethodParameters: parsedMethodParameters,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    // Data

    const androidVersion = this.state.androidVersion;
    const appVersionNameCode = this.state.appVersionNameCode;
    const stackTrace = this.state.stackTrace;
    const reportDate = this.state.reportDate;
    const level = this.state.level;
    const message = this.state.message;
    const methodName = this.state.methodName;
    const methodParameters = this.state.methodParameters;
    const classPackage = this.state.classPackage;
    const appUsageId = this.state.appUsageId;
    const userSession = this.state.userSession;
    const parsedMethodParameters = this.state.parsedMethodParameters;

    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/android-apps/${this.state.appId}?tabKey=LOGS`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Log detail</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="log_detail_container">
              {classPackage || methodName ? (
                <label className="log_detail_class_method_name">
                  {classPackage ? classPackage : null}
                  {methodName ? ` in ${methodName}` : null}
                </label>
              ) : null}

              <label className="log_detail_message">{message}</label>
              <hr />
              <div
                className="log_detail_basic_info_container"
                style={{ width: "25%" }}
              >
                <label className="log_detail_basic_info_title">Level</label>
                <label className="log_detail_basic_info_value">{level}</label>
              </div>
              <div
                className="log_detail_basic_info_container"
                style={{ width: "25%" }}
              >
                <label className="log_detail_basic_info_title">
                  Report date
                </label>
                <label className="log_detail_basic_info_value">
                  {reportDate}
                </label>
              </div>
              <div
                className="log_detail_basic_info_container"
                style={{ width: "25%" }}
              >
                <label className="log_detail_basic_info_title">
                  App version
                </label>
                <label className="log_detail_basic_info_value">
                  {appVersionNameCode}
                </label>
              </div>
              <div
                className="log_detail_basic_info_container"
                style={{ width: "25%" }}
              >
                <label className="log_detail_basic_info_title">
                  Android version
                </label>
                <label className="log_detail_basic_info_value">
                  {androidVersion}
                </label>
              </div>
              <hr />
              {methodParameters ? (
                <div>
                  <label className="log_detail_title">Method parameters</label>
                  <div
                    className="log_detail_value"
                    dangerouslySetInnerHTML={{ __html: parsedMethodParameters }}
                  />
                </div>
              ) : null}

              {stackTrace ? (
                <div>
                  <label className="log_detail_title">Stack trace</label>
                  <div
                    className="log_detail_value"
                    dangerouslySetInnerHTML={{ __html: stackTrace }}
                  />
                </div>
              ) : null}

              {appUsageId ? (
                <div>
                  <label className="log_detail_title">App usage</label>
                  <label className="log_detail_value">{appUsageId}</label>
                </div>
              ) : null}

              {userSession ? (
                <div>
                  <label className="log_detail_title">User session</label>
                  <label className="log_detail_value">{userSession}</label>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
