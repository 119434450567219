import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./components/dashboard.component";
import ReactWebsitesList from "./components/react-website/react-website-list.component";
import InfrastructureList from "./components/infrastructure/infrastructure-list.component";
import InfrastructureComponent from "./components/infrastructure/infrastructure-server.component";
import ApiList from "./components/api/api-list.component";
import ApiComponent from "./components/api/api.component";
import ApiLogDetail from "./components/api/api-log-detail.component";
import ApiLogGroupDetail from "./components/api/api-loggroup-detail.component";
import ApiHttpTraceDetail from "./components/api/api-http-trace-detail.component";
import ApiHttpTraceGroupDetail from "./components/api/api-http-trace-group-detail.component";
import ReactWebsite from "./components/react-website/react-website.component";
import AndroidAppList from "./components/android/android-app-list.component";
import AndroidAppComponent from "./components/android/android-app.component";
import AndroidAppLogDetail from "./components/android/android-app-log-detail.component";
import AndroidLogGroupDetail from "./components/android/android-app-loggroup-detail.component";
import AccountsList from "./components/account/account-list.component";
import AccountInfo from "./components/account/account-info.component";

class App extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/react-websites" element={<ReactWebsitesList />} />
          <Route
            path="/react-websites/:reactWebsiteId"
            element={<ReactWebsite />}
          />
          <Route path="/infrastructure" element={<InfrastructureList />} />
          <Route
            path="/infrastructure/:serverId"
            element={<InfrastructureComponent />}
          />
          <Route path="/apis" element={<ApiList />} />
          <Route path="/apis/:apiId" element={<ApiComponent />} />
          <Route path="/apis/:apiId/log/:logId" element={<ApiLogDetail />} />
          <Route
            path="/apis/:apiId/log-group/:logGroupId"
            element={<ApiLogGroupDetail />}
          />
          <Route
            path="/apis/:apiId/http-trace/:httpTraceId"
            element={<ApiHttpTraceDetail />}
          />
          <Route
            path="/apis/:apiId/http-trace-group/:httpTraceGroupId"
            element={<ApiHttpTraceGroupDetail />}
          />
          <Route path="/android-apps" element={<AndroidAppList />} />
          <Route
            path="/android-apps/:appId"
            element={<AndroidAppComponent />}
          />
          <Route
            path="/android-apps/:appId/log/:logId"
            element={<AndroidAppLogDetail />}
          />
          <Route
            path="/android-apps/:apiId/log-group/:logGroupId"
            element={<AndroidLogGroupDetail />}
          />

          <Route path="/accounts" element={<AccountsList />} />
          <Route path="/accounts/:accountId" element={<AccountInfo />} />
        </Routes>
      </div>
    );
  }
}

export default App;
