import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import {
  BarChart,
  Bar,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
import reactWebsitesService from "../../services/react-websites.service";

export default class ReactWebsiteAvailabilityStatistics extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      websiteId: null,
      startDateTime: null,
      endDateTime: null,
      availabilityStatisticsInPeriod: [],
      availabilityPercentInPeriod: 0,
      unavailabilityPercentInPeriod: 0,
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      websiteId: this.props.websiteId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  getStatistics() {
    var body = {
      id: this.state.websiteId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };

    console.log(body);
    reactWebsitesService
      .getAvailable(body)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          availabilityStatisticsInPeriod:
            responseData.availabilityStatisticsInPeriod,
          availabilityPercentInPeriod: responseData.availabilityPercentInPeriod,
          unavailabilityPercentInPeriod:
            responseData.unavailabilityPercentInPeriod,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data

    const availabilityStatisticsInPeriod =
      this.state.availabilityStatisticsInPeriod;
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Available: {payload[0].payload.availablePercentage}%<br />
              Unavailable: {payload[0].payload.notAvailablePercentage}%
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbar setPeriod={this.setPeriod} />

        <div className="website_availability_graph">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={availabilityStatisticsInPeriod}>
              <YAxis />
              <ReferenceLine y={0} stroke="#212121" />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="availablePercentage" stackId="a" fill="#a5d6a7" />
              <Bar
                dataKey="notAvailablePercentage"
                stackId="a"
                fill="#ef9a9a"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
