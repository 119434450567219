import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import memorySizeHelper from "../../helpers/memory-size-helper";
import apiService from "../../services/api.service";
import graphHelper from "../../helpers/graph-helper";

export default class ApiHistoricUsage extends Component {
  constructor(props) {
    super(props);
    this.getUsage = this.getUsage.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      apiId: null,
      startDateTime: null,
      endDateTime: null,
      usage: [],
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      apiId: this.props.apiId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getUsage();
  }

  getUsage() {
    var body = {
      id: this.state.apiId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };

    console.log(body);
    apiService
      .getHistoricMetrics(body)
      .then((response) => {
        this.setState({
          usage: response.data,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const processorUsage = [];
    const memoryUsage = [];
    const apiStatuses = [];
    const databaseStatuses = [];
    const pingStatuses = [];
    const livenessStateStatuses = [];
    const readinessStateStatuses = [];

    this.state.usage.forEach((usage) => {
      memoryUsage.push({
        period: usage.period,
        Avg: usage.avgMemoryUsed,
        Min: usage.minMemoryUsed,
        Max: usage.maxMemoryUsed,
      });

      processorUsage.push({
        period: usage.period,
        Avg: usage.avgCpuUsage,
        Min: usage.minCpuUsage,
        Max: usage.maxCpuUsage,
      });

      if (usage.apiStatusStatistics) {
        const tempApiStatus = usage.apiStatusStatistics;
        tempApiStatus["period"] = usage.period;
        apiStatuses.push(tempApiStatus);
      }

      if (usage.databaseStatusStatistics) {
        const tempDbStatus = usage.databaseStatusStatistics;
        tempDbStatus["period"] = usage.period;
        databaseStatuses.push(tempDbStatus);
      }

      if (usage.pingStatusStatistics) {
        const tempPingStatus = usage.pingStatusStatistics;
        tempPingStatus["period"] = usage.period;
        pingStatuses.push(tempPingStatus);
      }

      if (usage.livenessStateStatusStatistics) {
        const tempLivenessStatuses = usage.livenessStateStatusStatistics;
        tempLivenessStatuses["period"] = usage.period;
        livenessStateStatuses.push(tempLivenessStatuses);
      }

      if (usage.readinessStateStatusStatistics) {
        const tempReadinessStatuses = usage.readinessStateStatusStatistics;
        tempReadinessStatuses["period"] = usage.period;
        readinessStateStatuses.push(tempReadinessStatuses);
      }
    });

    const ProcessorTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Max: {payload[0].payload.Max}%<br />
              Avg: {payload[0].payload.Avg}%<br />
              Min: {payload[0].payload.Min}%
            </p>
          </div>
        );
      }

      return null;
    };

    const MemoryTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Max: {memorySizeHelper.convertFromBytes(payload[0].payload.Max)}
              <br />
              Avg: {memorySizeHelper.convertFromBytes(payload[0].payload.Avg)}
              <br />
              Min: {memorySizeHelper.convertFromBytes(payload[0].payload.Min)}
            </p>
          </div>
        );
      }

      return null;
    };

    const StatusTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Up: {payload[0].payload.UP ? payload[0].payload.UP : 0} %
              <br />
              Down: {payload[0].payload.DOWN ? payload[0].payload.DOWN : 0} %
              <br />
              Out of service:{" "}
              {payload[0].payload.OUT_OF_SERVICE
                ? payload[0].payload.OUT_OF_SERVICE
                : 0}{" "}
              %
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbar setPeriod={this.setPeriod} />
        {processorUsage.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Processor</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={processorUsage}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<ProcessorTooltip />} />
                  <YAxis tickFormatter={graphHelper.formatPercent} />
                  <Legend />
                  <Line dataKey="Max" stroke="#ef9a9a" />
                  <Line dataKey="Avg" stroke="#ffcc80" />
                  <Line dataKey="Min" stroke="#a5d6a7" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {memoryUsage.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Memory</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={memoryUsage}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<MemoryTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.convertFromBytesTickFormat}
                  />
                  <Legend />
                  <Line dataKey="Max" stroke="#ef9a9a" />
                  <Line dataKey="Avg" stroke="#ffcc80" />
                  <Line dataKey="Min" stroke="#a5d6a7" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {apiStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">API status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={apiStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {databaseStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Database status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={databaseStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {pingStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Ping status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={pingStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {livenessStateStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Liveness state</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={livenessStateStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {readinessStateStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Readiness state</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={readinessStateStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
