import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import numberHelper from "../../helpers/number-helper";
import androidService from "../../services/android.service";
import PeriodToolbar from "../others/period-toolbar.component";

export default class AndroidAppStatistics extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      appId: null,
      startDateTime: null,
      endDateTime: null,
      totalNewInstallationsInPeriod: 0,
      totalActiveInstallationsInPeriod: 0,
      newInstallationsInPeriodStatics: [],
      activeInstallationsInPeriodStatics: [],
      totalAndroidVersionsInPeriod: [],
      totalAppVersionsInPeriod: [],
    };
  }

  componentDidMount() {
    this.setState({
      appId: this.props.appId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  getBody() {
    return {
      id: this.state.appId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };
  }

  getStatistics() {
    androidService
      .getStatistics(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          totalNewInstallationsInPeriod:
            responseData.totalNewInstallationsInPeriod,
          totalActiveInstallationsInPeriod:
            responseData.totalActiveInstallationsInPeriod,
          newInstallationsInPeriodStatics:
            responseData.newInstallationsInPeriodStatics,
          activeInstallationsInPeriodStatics:
            responseData.activeInstallationsInPeriodStatics,
          totalAndroidVersionsInPeriod:
            responseData.totalAndroidVersionsInPeriod,
          totalAppVersionsInPeriod: responseData.totalAppVersionsInPeriod,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    const totalNewInstallationsInPeriod =
      this.state.totalNewInstallationsInPeriod;
    const totalActiveInstallationsInPeriod =
      this.state.totalActiveInstallationsInPeriod;
    const newInstallationsInPeriodStatics =
      this.state.newInstallationsInPeriodStatics;
    const activeInstallationsInPeriodStatics =
      this.state.activeInstallationsInPeriodStatics;
    const totalAndroidVersionsInPeriod =
      this.state.totalAndroidVersionsInPeriod;
    const totalAppVersionsInPeriod = this.state.totalAppVersionsInPeriod;

    const NewInstallationsTooltips = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              New installations:{" "}
              {numberHelper.format(payload[0].payload.Installations)}
            </p>
          </div>
        );
      }

      return null;
    };

    const ActiveInstallationsTooltips = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Active installations:{" "}
              {numberHelper.format(payload[0].payload.Active)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbar setPeriod={this.setPeriod} />

        <div className="android_app_statistics_container">
          <label className="android_app_statistics_title">
            New installations
          </label>
          <div className="android_app_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={newInstallationsInPeriodStatics}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<NewInstallationsTooltips />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Bar dataKey="Installations" fill="#a5d6a7" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="android_app_statistics_value">
            Total: {totalNewInstallationsInPeriod}
          </label>
        </div>
        <div className="android_app_statistics_container">
          <label className="android_app_statistics_title">
            Active installations
          </label>
          <div className="android_app_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={activeInstallationsInPeriodStatics}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<ActiveInstallationsTooltips />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Bar dataKey="Active" fill="#a5d6a7" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="android_app_statistics_value">
            Total: {totalActiveInstallationsInPeriod}
          </label>
        </div>
        <div className="android_app_statistics_container2">
          <label className="android_app_statistics_title">
            Installations by Android versions
          </label>
          <div style={{ margin: "10px 0" }}>
            <label
              className="android_app_statistics_right_value"
              style={{ fontWeight: "bold" }}
            >
              Android version
            </label>
            <label
              className="android_app_statistics_left_value"
              style={{ fontWeight: "bold" }}
            >
              Installations
            </label>
          </div>
          {totalAndroidVersionsInPeriod.map((statistic) => (
            <div>
              <label className="android_app_statistics_right_value">
                Android {statistic.Version}
              </label>
              <label className="android_app_statistics_left_value">
                {statistic.Installations}
              </label>
            </div>
          ))}
        </div>
        <div className="android_app_statistics_container2">
          <label className="android_app_statistics_title">
            Installations by App versions
          </label>
          <div style={{ margin: "10px 0" }}>
            <label
              className="android_app_statistics_right_value"
              style={{ fontWeight: "bold" }}
            >
              App version
            </label>
            <label
              className="android_app_statistics_left_value"
              style={{ fontWeight: "bold" }}
            >
              Installations
            </label>
          </div>
          {totalAppVersionsInPeriod.map((statistic) => (
            <div>
              <label className="android_app_statistics_right_value">
                {statistic.Version}
              </label>
              <label className="android_app_statistics_left_value">
                {statistic.Installations}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
