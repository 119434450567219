import React, { Component } from "react";
import { Link } from "react-router-dom";
import CookieHelper from "../helpers/cookie-helper";
import Masonry from "react-masonry-css";

import ConsoleIcon from "../icons/console_blank.png";
import AccountMultipleIcon from "../icons/account_multiple.svg";
import AndroidIcon from "../icons/android.svg";
import ApiIcon from "../icons/api.svg";
import ReactIcon from "../icons/react.svg";
import ServerIcon from "../icons/server.svg";
import WebIcon from "../icons/web.svg";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.singOut = this.singOut.bind(this);

    this.state = {
      dashboardItems: [],
    };
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  componentDidMount() {
    var items = [];

    var accountItem = {
      title: "Accounts",
      path: "accounts",
      image: AccountMultipleIcon,
    };

    var androidItem = {
      title: "Android apps",
      path: "android-apps",
      image: AndroidIcon,
    };

    var apiItem = {
      title: "APIs",
      path: "apis",
      image: ApiIcon,
    };

    var infrastructureItem = {
      title: "Servers",
      path: "infrastructure",
      image: ServerIcon,
    };

    var reactWebsiteItem = {
      title: "React websites",
      path: "react-websites",
      image: ReactIcon,
    };

    items.push(accountItem);
    items.push(androidItem);
    items.push(apiItem);
    items.push(infrastructureItem);
    items.push(reactWebsiteItem);

    this.setState({
      dashboardItems: items,
    });

    document.title = "Console - davidsimak.dev";
  }

  render() {
    // Data
    const dashboardItems = this.state.dashboardItems;

    // UI changes
    const breakpointCols = {
      default: 4,
      1700: 3,
      1200: 2,
      800: 1,
    };

    return (
      <div>
        <div>
          <header className={this.state.header_class}>
            <div className="title">
              <img
                src={ConsoleIcon}
                alt="Console"
                height="50"
                width="50"
                style={{ marginLeft: "10px" }}
              />
              <h1>Console</h1>
            </div>

            <nav>
              <ul>
                <li>
                  <div className="dropdown">
                    <button
                      title="Account Info"
                      className="action_button action_account_info"
                    />
                    <div className="dropdown_content dropdown_content_account_info">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.davidsimak.dev/account/"
                      >
                        Account Info
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={this.singOut}>Sign out</a>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                <br />

                <Masonry
                  breakpointCols={breakpointCols}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {dashboardItems.map((item) => (
                    <Link className="row_dashboard_item" to={"/" + item.path}>
                      <div className="row_dashboard_item_div">
                        <img
                          src={item.image}
                          alt={item.title}
                          height="40"
                          width="40"
                          style={{ marginLeft: "10px" }}
                        />
                        <label className="row_dashboard_item_title">
                          {item.title}
                        </label>
                      </div>
                    </Link>
                  ))}
                </Masonry>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
