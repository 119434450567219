import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import numberHelper from "../../helpers/number-helper";
import {
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import PeriodToolbar from "../others/period-toolbar.component";
import androidService from "../../services/android.service";

export default class AndroidLogGroupDetail extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      lastSeenAgoIn: "",
      firstSeenAgoIn: "",
      lastSeenDate: "",
      firstSeenDate: "",
      totalAmount: 0,
      level: "",
      message: "",
      methodName: "",
      stackTrace: "",
      classPackage: "",
      last24HoursStatistics: [],
      last30DaysStatistics: [],
      last24HoursAppVersions: [],
      last30DaysAppVersions: [],
      last24HoursAndroidVersions: [],
      last30DaysAndroidVersions: [],
      totalLast24HoursAffectedInstallations: 0,
      totalLast30DaysAffectedInstallations: 0,
      totalLast24HoursEvents: 0,
      totalLast30DaysEvents: 0,
      totalAffectedInstallations: 0,
      loadingItems: false,
      tabKey: "OVERVIEW",
      startDateTime: null,
      endDateTime: null,
      logs: [],
      metrics: [],
      total: 0,
      maxLogPages: 1,
      currentLogPage: 1,
      search: "",
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var logGroupId = pathSplit[pathSplit.length - 1];
    var appId = pathSplit[pathSplit.length - 3];

    document.title = "Log group detail | Console - davidsimak.dev";

    this.setState(
      {
        appId: appId,
        logGroupId: logGroupId,
        loadingItems: true,
      },
      () => this.getData()
    );
  }

  getBody() {
    const body = {
      id: this.state.logGroupId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentLogPage - 1) * 50,
    };

    return body;
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getHistory()
    );
  }

  getHistory() {
    this.setState({
      loadingItems: true,
    });
    androidService
      .getLogGroupHistory(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          logs: responseData.logs,
          metrics: responseData.metrics,
          total: responseData.total,
          maxLogPages: Math.ceil(responseData.total / 50),
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  getData() {
    androidService
      .getLogGroup(this.state.logGroupId)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          lastSeenAgoIn: responseData.lastSeenAgoIn,
          firstSeenAgoIn: responseData.firstSeenAgoIn,
          lastSeenDate: responseData.lastSeenDate,
          firstSeenDate: responseData.firstSeenDate,
          totalAmount: responseData.totalAmount,
          level: responseData.level,
          message: responseData.message,
          methodName: responseData.methodName,
          stackTrace: responseData.stackTrace,
          classPackage: responseData.classPackage,
          last24HoursStatistics: responseData.last24HoursStatistics,
          last30DaysStatistics: responseData.last30DaysStatistics,
          totalLast24HoursEvents: responseData.totalLast24HoursEvents,
          totalLast30DaysEvents: responseData.totalLast30DaysEvents,
          last24HoursAppVersions: responseData.last24HoursAppVersions,
          last30DaysAppVersions: responseData.last30DaysAppVersions,
          last24HoursAndroidVersions: responseData.last24HoursAndroidVersions,
          last30DaysAndroidVersions: responseData.last30DaysAndroidVersions,
          totalLast24HoursAffectedInstallations:
            responseData.totalLast24HoursAffectedInstallations,
          totalLast30DaysAffectedInstallations:
            responseData.totalLast30DaysAffectedInstallations,
          totalAffectedInstallations: responseData.totalAffectedInstallations,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  pageBack() {
    const newCurrentLogPage = this.state.currentLogPage - 1;
    if (newCurrentLogPage === 0) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getHistory()
    );
  }

  pageNext() {
    const newCurrentLogPage = this.state.currentLogPage + 1;
    if (newCurrentLogPage > this.state.maxLogPages) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getHistory()
    );
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  render() {
    // Data
    const lastSeenAgoIn = this.state.lastSeenAgoIn;
    const firstSeenAgoIn = this.state.firstSeenAgoIn;
    const lastSeenDate = this.state.lastSeenDate;
    const firstSeenDate = this.state.firstSeenDate;
    const totalAmount = this.state.totalAmount;
    const level = this.state.level;
    const message = this.state.message;
    const methodName = this.state.methodName;
    const stackTrace = this.state.stackTrace;
    const classPackage = this.state.classPackage;
    const last24HoursStatistics = this.state.last24HoursStatistics;
    const last30DaysStatistics = this.state.last30DaysStatistics;
    const last24HoursAppVersions = this.state.last24HoursAppVersions;
    const last30DaysAppVersions = this.state.last30DaysAppVersions;
    const last24HoursAndroidVersions = this.state.last24HoursAndroidVersions;
    const last30DaysAndroidVersions = this.state.last30DaysAndroidVersions;
    const totalLast24HoursAffectedInstallations =
      this.state.totalLast24HoursAffectedInstallations;
    const totalLast30DaysAffectedInstallations =
      this.state.totalLast30DaysAffectedInstallations;
    const totalLast24HoursEvents = this.state.totalLast24HoursEvents;
    const totalLast30DaysEvents = this.state.totalLast30DaysEvents;
    const totalAffectedInstallations = this.state.totalAffectedInstallations;

    const loadingItems = this.state.loadingItems;
    const tabKey = this.state.tabKey;

    const logs = this.state.logs;
    const metrics = this.state.metrics;
    const total = this.state.total;
    const currnetPage = this.state.currentLogPage;
    const maxPages = this.state.maxLogPages;

    const LogsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Events: {numberHelper.format(payload[0].payload.Events)}
            </p>
          </div>
        );
      }

      return null;
    };

    const LogsTooltipHistory = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Events: {numberHelper.format(payload[0].payload.Total)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/android-apps/${this.state.appId}?tabKey=LOG_GROUPS`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Log group detail</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="http_trace_group_detail_tabs">
              <button
                className={
                  "http_trace_group_detail_tabs_button " +
                  (tabKey === "OVERVIEW" ? "website_tabs_button_selected" : "")
                }
                onClick={() => this.setTabKey("OVERVIEW")}
              >
                Overview
              </button>
              <button
                className={
                  "http_trace_group_detail_tabs_button " +
                  (tabKey === "HISTORY" ? "website_tabs_button_selected" : "")
                }
                onClick={() => this.setTabKey("HISTORY")}
              >
                History
              </button>
            </div>
            <br />
            {tabKey === "OVERVIEW" ? (
              <div>
                <div className="log_detail_container">
                  {classPackage || methodName ? (
                    <label className="log_detail_class_method_name">
                      {classPackage}
                      {methodName ? ` in ${methodName}` : null}
                    </label>
                  ) : null}

                  <label className="log_detail_message">{message}</label>
                  <hr />
                  <div
                    className="log_group_detail_basic_info_container"
                    style={{ width: "16%" }}
                  >
                    <label className="log_detail_basic_info_title">Level</label>
                    <label className="log_detail_basic_info_value">
                      {level}
                    </label>
                    <label className="log_detail_basic_info_sub_value"></label>
                  </div>
                  <div
                    className="log_group_detail_basic_info_container"
                    style={{ width: "16%" }}
                  >
                    <label className="log_detail_basic_info_title">Count</label>
                    <label className="log_detail_basic_info_value">
                      {totalAmount}
                    </label>
                    <label className="log_detail_basic_info_sub_value"></label>
                  </div>
                  <div
                    className="log_group_detail_basic_info_container"
                    style={{ width: "16%" }}
                  >
                    <label className="log_detail_basic_info_title">
                      Installations
                    </label>
                    <label className="log_detail_basic_info_value">
                      {totalAffectedInstallations}
                    </label>
                    <label className="log_detail_basic_info_sub_value"></label>
                  </div>
                  <div
                    className="log_group_detail_basic_info_container"
                    style={{ width: "26%" }}
                  >
                    <label className="log_detail_basic_info_title">
                      First seen
                    </label>
                    <label className="log_detail_basic_info_value">
                      {firstSeenAgoIn}
                    </label>
                    <label className="log_detail_basic_info_sub_value">
                      {firstSeenDate}
                    </label>
                  </div>
                  <div
                    className="log_group_detail_basic_info_container"
                    style={{ width: "26%" }}
                  >
                    <label className="log_detail_basic_info_title">
                      Last seen
                    </label>
                    <label className="log_detail_basic_info_value">
                      {lastSeenAgoIn}
                    </label>
                    <label className="log_detail_basic_info_sub_value">
                      {lastSeenDate}
                    </label>
                  </div>
                  <hr />

                  <label className="log_group_detail_graph_statistics_title">
                    Last 24 hours
                  </label>
                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Events
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last24HoursStatistics}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<LogsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar dataKey="Events" fill="#b0bec5" stackId="a" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Count: {totalLast24HoursEvents}
                    </div>
                  </div>
                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Affected installations
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last24HoursStatistics}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<LogsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar
                            dataKey="Installations"
                            fill="#b0bec5"
                            stackId="a"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Count: {totalLast24HoursAffectedInstallations}
                    </div>
                  </div>
                  <div className="android_app_statistics_container2">
                    <label className="android_app_statistics_title">
                      Installations by Android versions
                    </label>
                    <div style={{ margin: "10px 0" }}>
                      <label
                        className="android_app_statistics_right_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Android version
                      </label>
                      <label
                        className="android_app_statistics_left_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Installations
                      </label>
                    </div>
                    {last24HoursAndroidVersions.map((statistic) => (
                      <div>
                        <label className="android_app_statistics_right_value">
                          Android {statistic.Version}
                        </label>
                        <label className="android_app_statistics_left_value">
                          {statistic.Installations}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="android_app_statistics_container2">
                    <label className="android_app_statistics_title">
                      Installations by App versions
                    </label>
                    <div style={{ margin: "10px 0" }}>
                      <label
                        className="android_app_statistics_right_value"
                        style={{ fontWeight: "bold" }}
                      >
                        App version
                      </label>
                      <label
                        className="android_app_statistics_left_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Installations
                      </label>
                    </div>
                    {last24HoursAppVersions.map((statistic) => (
                      <div>
                        <label className="android_app_statistics_right_value">
                          {statistic.Version}
                        </label>
                        <label className="android_app_statistics_left_value">
                          {statistic.Installations}
                        </label>
                      </div>
                    ))}
                  </div>
                  <hr />

                  <label className="log_group_detail_graph_statistics_title">
                    Last 30 days
                  </label>
                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Events
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last30DaysStatistics}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<LogsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar dataKey="Events" fill="#b0bec5" stackId="a" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Count: {totalLast30DaysEvents}
                    </div>
                  </div>
                  <div className="log_group_detail_graph_statistics_container">
                    <label className="log_group_detail_graph_statistics_title">
                      Affected installations
                    </label>
                    <div className="log_group_detail_graph">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={last30DaysStatistics}>
                          <ReferenceLine y={0} stroke="#212121" />
                          <Tooltip content={<LogsTooltip />} />
                          <YAxis tickFormatter={numberHelper.format} />
                          <Bar
                            dataKey="Installations"
                            fill="#b0bec5"
                            stackId="a"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="log_group_detail_graph_statistics_total">
                      Count: {totalLast30DaysAffectedInstallations}
                    </div>
                  </div>
                  <div className="android_app_statistics_container2">
                    <label className="android_app_statistics_title">
                      Installations by Android versions
                    </label>
                    <div style={{ margin: "10px 0" }}>
                      <label
                        className="android_app_statistics_right_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Android version
                      </label>
                      <label
                        className="android_app_statistics_left_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Installations
                      </label>
                    </div>
                    {last30DaysAndroidVersions.map((statistic) => (
                      <div>
                        <label className="android_app_statistics_right_value">
                          Android {statistic.Version}
                        </label>
                        <label className="android_app_statistics_left_value">
                          {statistic.Installations}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="android_app_statistics_container2">
                    <label className="android_app_statistics_title">
                      Installations by App versions
                    </label>
                    <div style={{ margin: "10px 0" }}>
                      <label
                        className="android_app_statistics_right_value"
                        style={{ fontWeight: "bold" }}
                      >
                        App version
                      </label>
                      <label
                        className="android_app_statistics_left_value"
                        style={{ fontWeight: "bold" }}
                      >
                        Installations
                      </label>
                    </div>
                    {last30DaysAppVersions.map((statistic) => (
                      <div>
                        <label className="android_app_statistics_right_value">
                          {statistic.Version}
                        </label>
                        <label className="android_app_statistics_left_value">
                          {statistic.Installations}
                        </label>
                      </div>
                    ))}
                  </div>

                  {stackTrace ? (
                    <div>
                      <hr />
                      <label className="log_detail_title">Stack trace</label>
                      <label className="log_detail_value">{stackTrace}</label>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {tabKey === "HISTORY" ? (
              <div>
                <PeriodToolbar setPeriod={this.setPeriod} />
                <div className="api_logs_statistics_container">
                  <div className="api_logs_statistics_graph">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={metrics}>
                        <ReferenceLine y={0} stroke="#212121" />
                        <Tooltip content={<LogsTooltipHistory />} />
                        <YAxis tickFormatter={numberHelper.format} />
                        <Bar dataKey="Total" fill="#b0bec5" stackId="a" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <label className="api_logs_statistics_summary">
                    Total: {numberHelper.format(total)}
                  </label>
                </div>

                <div className="log_paging_container">
                  <button
                    className="action_button_log action_log_page_back"
                    disabled={currnetPage === 1}
                    onClick={this.pageBack}
                  />
                  <label className="log_page_count">
                    {currnetPage}/{maxPages}
                  </label>
                  <button
                    className="action_button_log action_log_page_next"
                    disabled={currnetPage === maxPages}
                    onClick={this.pageNext}
                  />
                </div>

                <table className="logs_table logs_table_top">
                  <tr>
                    <th className="android_logs_table_description">
                      Description
                    </th>
                    <th className="android_logs_table_column">Date</th>
                    <th className="android_logs_table_small_column">Version</th>
                    <th className="android_logs_table_small_column">Android</th>
                  </tr>
                </table>
                {logs.map((log) => (
                  <div className="row_website_item">
                    <div className="row_logs_item_div">
                      <table className="logs_table">
                        <tr>
                          <td className="android_logs_table_description">
                            {log.appUsageId ? (
                              <label className="log_item_sessions">
                                <b>App usage:</b> {log.appUsageId}
                              </label>
                            ) : null}
                            {log.userSession ? (
                              <label className="log_item_sessions">
                                <b>User session:</b> {log.userSession}
                              </label>
                            ) : null}
                            {log.installationId ? (
                              <label className="log_item_sessions">
                                <b>Installation ID:</b> {log.installationId}
                              </label>
                            ) : null}
                          </td>
                          <td className="android_logs_table_column">
                            {log.reportDate}
                          </td>
                          <td className="android_logs_table_small_column">
                            {log.appVersionNameCode}
                          </td>
                          <td className="android_logs_table_small_column">
                            {log.androidVersion}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}

                <div className="log_paging_container">
                  <button
                    className="action_button_log action_log_page_back"
                    disabled={currnetPage === 1}
                    onClick={this.pageBack}
                  />
                  <label className="log_page_count">
                    {currnetPage}/{maxPages}
                  </label>
                  <button
                    className="action_button_log action_log_page_next"
                    disabled={currnetPage === maxPages}
                    onClick={this.pageNext}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
