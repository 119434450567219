import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import ServerInfo from "./server-info.component";
import ServerCurrentUsageStatistics from "./server-current-usage.component";
import ServerHistoricUsage from "./server-historic-usage.component";
import ServerDailyReport from "./server-daily-report.component";

export default class InfrastructureComponent extends Component {
  constructor(props) {
    super(props);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.setBasicInfo = this.setBasicInfo.bind(this);

    this.state = {
      id: null,
      name: null,
      loadingItems: true,
      tabKey: "",
    };
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var infrastructureId = pathSplit[pathSplit.length - 1];
    this.setState({
      id: infrastructureId,
      tabKey: "INFO",
    });
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  setBasicInfo(name) {
    this.setState({
      name: name,
    });
  }

  render() {
    // Data
    const id = this.state.id;
    const name = this.state.name;
    const tabKey = this.state.tabKey;

    // UI changes
    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/infrastructure">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>{name} (Server)</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                <div className="infrastructure_tabs">
                  <button
                    className={
                      "infrastructure_tabs_button " +
                      (tabKey === "INFO" ? "website_tabs_button_selected" : "")
                    }
                    onClick={() => this.setTabKey("INFO")}
                  >
                    Information
                  </button>
                  <button
                    className={
                      "infrastructure_tabs_button " +
                      (tabKey === "CURRENT_USAGE"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("CURRENT_USAGE")}
                  >
                    Current usage
                  </button>
                  <button
                    className={
                      "infrastructure_tabs_button " +
                      (tabKey === "HISTORIC_USAGE"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("HISTORIC_USAGE")}
                  >
                    Historic usage
                  </button>
                  <button
                    className={
                      "infrastructure_tabs_button " +
                      (tabKey === "REPORTS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("REPORTS")}
                  >
                    Daily reports
                  </button>
                </div>

                {tabKey === "INFO" ? (
                  <ServerInfo
                    serverId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                    setBasicInfo={this.setBasicInfo}
                  />
                ) : null}

                {tabKey === "CURRENT_USAGE" ? (
                  <ServerCurrentUsageStatistics
                    serverId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "HISTORIC_USAGE" ? (
                  <ServerHistoricUsage
                    serverId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "REPORTS" ? (
                  <ServerDailyReport
                    serverId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
