import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import ApiInfoHealth from "./api-info-health.component";
import ApiHistoricUsage from "./api-historic-usage.component";
import ApiLogsList from "./api-logs-list.component";
import ApiLogGroupsList from "./api-loggroups-list.component";
import ApiHttpTracesList from "./api-http-traces-list.component";
import ApihttpTraceGroupsList from "./api-http-trace-groups-list.component";
import ApiDailyReport from "./api-daily-report.component";

export default class ApiComponent extends Component {
  constructor(props) {
    super(props);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.setTabKey = this.setTabKey.bind(this);
    this.setBasicInfo = this.setBasicInfo.bind(this);

    this.state = {
      id: null,
      name: null,
      loadingItems: true,
      tabKey: "",
    };
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var apiId = pathSplit[pathSplit.length - 1];

    this.setState({
      id: apiId,
      tabKey: "INFO_HEALTH",
    });
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  setTabKey(value) {
    this.setState({
      tabKey: value,
    });
  }

  setBasicInfo(name) {
    var tabKey = "";

    if (window.location.search !== "") {
      tabKey = window.location.search.replace("?tabKey=", "");
    } else {
      tabKey = "INFO_HEALTH";
    }

    this.setState({
      name: name,
      tabKey: tabKey,
    });

    document.title = name + " (API) | Console - davidsimak.dev";
  }

  render() {
    // Data
    const id = this.state.id;
    const name = this.state.name;
    const tabKey = this.state.tabKey;

    // UI changes
    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/apis">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>{name} (API)</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                <div className="api_tabs">
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "INFO_HEALTH"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("INFO_HEALTH")}
                    style={{ width: "calc(15% - 30px)" }}
                  >
                    Info and health
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "HISTORIC_USAGE"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("HISTORIC_USAGE")}
                  >
                    Historic usage
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "LOGS" ? "website_tabs_button_selected" : "")
                    }
                    onClick={() => this.setTabKey("LOGS")}
                  >
                    Logs
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "LOG_GROUPS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("LOG_GROUPS")}
                  >
                    Log groups
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "HTTP_TRACES"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("HTTP_TRACES")}
                  >
                    Http traces
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "HTTP_TRACE_GROUPS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("HTTP_TRACE_GROUPS")}
                  >
                    Http trace groups
                  </button>
                  <button
                    className={
                      "api_tabs_button " +
                      (tabKey === "REPORTS"
                        ? "website_tabs_button_selected"
                        : "")
                    }
                    onClick={() => this.setTabKey("REPORTS")}
                    style={{ width: "calc(15% - 30px)" }}
                  >
                    Daily reports
                  </button>
                </div>

                {tabKey === "INFO_HEALTH" ? (
                  <ApiInfoHealth
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                    setBasicInfo={this.setBasicInfo}
                  />
                ) : null}

                {tabKey === "HISTORIC_USAGE" ? (
                  <ApiHistoricUsage
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "LOGS" ? (
                  <ApiLogsList
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "LOG_GROUPS" ? (
                  <ApiLogGroupsList
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "HTTP_TRACES" ? (
                  <ApiHttpTracesList
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "HTTP_TRACE_GROUPS" ? (
                  <ApihttpTraceGroupsList
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}

                {tabKey === "REPORTS" ? (
                  <ApiDailyReport
                    apiId={id}
                    onLoadingStateChange={this.onLoadingStateChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
