import http from "../helpers/http-common-reporting";

class ReactWebsiteService {
  getAll() {
    return http.get(`/react-website/`);
  }

  getInfo(id) {
    return http.get(`/react-website/${id}`);
  }

  getAvailable(data) {
    return http.post("/react-website/availability", data);
  }

  getVisits(data) {
    return http.post("/react-website/visits", data);
  }

  getDailyReport(data) {
    return http.post("/react-website/daily-report", data);
  }
}

export default new ReactWebsiteService();
