class TextHelper {
  getShortenText50Chars(text) {
    if (text.length <= 50) {
      return text;
    }

    return text.substring(0, 47) + "...";
  }
}

export default new TextHelper();
