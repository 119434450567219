import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import { Link } from "react-router-dom";
import androidService from "../../services/android.service";

export default class AndroidAppCrashGroupsList extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.search = this.search.bind(this);
    this.cleanSearch = this.cleanSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      appId: null,
      startDateTime: null,
      endDateTime: null,
      logGroups: [],
      total: 0,
      maxLogPages: 1,
      currentLogPage: 1,
      search: "",
    };
  }

  cleanSearch() {
    this.setState(
      {
        search: "",
      },
      () => this.getData()
    );
  }

  search() {
    this.getData();
  }

  componentDidMount() {
    this.setState({
      appId: this.props.appId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  onSearchChange(event) {
    this.setState({
      search: event.target.value,
    });
  }

  getBody() {
    const body = {
      id: this.state.appId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentLogPage - 1) * 50,
      level: "CRASH",
    };

    const search = this.state.search;

    if (search.length === 0) {
      return body;
    }

    const searchArray = search.includes('"|') ? search.split('"|') : [search];

    searchArray.forEach((searchItem) => {
      if (!searchItem.includes(":")) {
        return;
      }

      const searchItemType = searchItem.split(':"')[0];
      const searchItemValue = searchItem
        .replace(searchItemType + ':"', "")
        .replace(/"$/, "");

      body[searchItemType] = searchItemValue;
    });
    return body;
  }

  getStatistics() {
    androidService
      .getLogGroups(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          logGroups: responseData.logGroups,
          maxLogPages: Math.ceil(responseData.logGroups.length / 50),
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  pageBack() {
    const newCurrentLogPage = this.state.currentLogPage - 1;
    if (newCurrentLogPage === 0) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  pageNext() {
    const newCurrentLogPage = this.state.currentLogPage + 1;
    if (newCurrentLogPage > this.state.maxLogPages) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  render() {
    const logGroups = this.state.logGroups;
    const currnetPage = this.state.currentLogPage;
    const maxPages = this.state.maxLogPages;
    const search = this.state.search;

    return (
      <div>
        <div className="log_search_container">
          <input
            type="text"
            onChange={this.onSearchChange}
            value={search}
            placeholder="Search"
            className="log_search_input"
          />
          <button
            onClick={this.cleanSearch}
            className="action_button action_clean_search"
          />
          <button
            onClick={this.search}
            className="action_button action_search_logs"
          />
        </div>
        <PeriodToolbar setPeriod={this.setPeriod} />
        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>

        <table className="logs_table logs_table_top">
          <tr>
            <th className="android_logs_table_small_column">Type</th>
            <th className="android_logs_table_description">Description</th>
            <th className="android_logs_table_small_column">Last seen</th>
            <th className="android_logs_table_small_column">Count</th>
            <th className="android_logs_table_small_column">Installations</th>
          </tr>
        </table>
        {logGroups.map((logGroup) => (
          <Link className="row_website_item" to={"log-group/" + logGroup.id}>
            <div className="row_logs_item_div">
              <table className="logs_table">
                <tr>
                  <td className="android_logs_table_small_column">
                    {logGroup.level}
                  </td>
                  <td className="android_logs_table_description">
                    {logGroup.classPackage || logGroup.methodName ? (
                      <label className="log_item_class_package_method_name">
                        {logGroup.classPackage ? logGroup.classPackage : ""}
                        {logGroup.methodName
                          ? ` in ${logGroup.methodName}`
                          : null}
                      </label>
                    ) : null}

                    <label className="log_item_message">
                      {logGroup.message}
                    </label>
                  </td>
                  <td className="android_logs_table_small_column">
                    <label title={logGroup.lastSeenDate}>
                      {logGroup.lastSeenAgo}
                    </label>
                  </td>
                  <td className="android_logs_table_small_column">
                    {logGroup.amount}
                  </td>
                  <td className="android_logs_table_small_column">
                    {logGroup.affectedInstallations}
                  </td>
                </tr>
              </table>
            </div>
          </Link>
        ))}

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>
      </div>
    );
  }
}
