class GraphHelper {
  getColor(percentage) {
    if (percentage < 70) {
      return "#a5d6a7";
    }

    if (percentage < 90) {
      return "#ffcc80";
    }

    return "#ef9a9a";
  }

  convertFromMegaBytesTickFormat(megaBytes) {
    const gigabyte = 1024;

    if (megaBytes >= gigabyte) {
      return Math.round(megaBytes / gigabyte) + " GB";
    }

    return megaBytes + " MB";
  }

  convertFromBytesTickFormat(bytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes >= megabyte) {
      return Math.round(bytes / megabyte) + " MB";
    }

    if (bytes >= kilobyte) {
      return Math.round(bytes / kilobyte) + " KB";
    }

    return bytes + "B";
  }

  formatPercent(percentage) {
    return Math.round(percentage * 1000) / 1000 + " %";
  }
}

export default new GraphHelper();
