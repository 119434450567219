import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import apiService from "../../services/api.service";
import numberHelper from "../../helpers/number-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import { Link } from "react-router-dom";

export default class ApiHttpTracesList extends Component {
  constructor(props) {
    super(props);
    this.getStatistics = this.getStatistics.bind(this);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageNext = this.pageNext.bind(this);
    this.search = this.search.bind(this);
    this.cleanSearch = this.cleanSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getBody = this.getBody.bind(this);

    this.state = {
      apiId: null,
      startDateTime: null,
      endDateTime: null,
      traces: [],
      metrics: [],
      total: 0,
      maxLogPages: 1,
      currentLogPage: 1,
      search: "",
    };
  }

  cleanSearch() {
    this.setState(
      {
        search: "",
      },
      () => this.getData()
    );
  }

  search() {
    this.getData();
  }

  componentDidMount() {
    this.setState({
      apiId: this.props.apiId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getStatistics();
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  onSearchChange(event) {
    this.setState({
      search: event.target.value,
    });
  }

  getBody() {
    const body = {
      id: this.state.apiId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
      offset: (this.state.currentLogPage - 1) * 50,
    };

    const search = this.state.search;

    if (search.length === 0) {
      return body;
    }

    const searchArray = search.includes('"|') ? search.split('"|') : [search];

    searchArray.forEach((searchItem) => {
      if (!searchItem.includes(":")) {
        return;
      }

      const searchItemType = searchItem.split(':"')[0];
      const searchItemValue = searchItem
        .replace(searchItemType + ':"', "")
        .replace(/"$/, "");

      body[searchItemType] = searchItemValue;
    });
    return body;
  }

  getStatistics() {
    apiService
      .getHttpTraces(this.getBody())
      .then((response) => {
        const responseData = response.data;

        this.setState({
          traces: responseData.traces,
          metrics: responseData.metrics,
          total: responseData.total,
          maxLogPages: Math.ceil(responseData.total / 50),
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  pageBack() {
    const newCurrentLogPage = this.state.currentLogPage - 1;
    if (newCurrentLogPage === 0) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  pageNext() {
    const newCurrentLogPage = this.state.currentLogPage + 1;
    if (newCurrentLogPage > this.state.maxLogPages) {
      return;
    }

    this.setState(
      {
        currentLogPage: newCurrentLogPage,
      },
      () => this.getData()
    );
  }

  render() {
    const traces = this.state.traces;
    const metrics = this.state.metrics;
    const responseStatusesCount = this.state.responseStatusesCount;
    var totalTrace100 = 0;
    var totalTrace200 = 0;
    var totalTrace300 = 0;
    var totalTrace400 = 0;
    var totalTrace500 = 0;

    metrics.forEach((metric) => {
      var total100 = 0;
      var total200 = 0;
      var total300 = 0;
      var total400 = 0;
      var total500 = 0;

      Object.keys(metric.statusesCounts).forEach((responseStatus) => {
        const nResponseStatus = parseInt(responseStatus);

        if (100 <= nResponseStatus && nResponseStatus < 200) {
          total100 += metric.statusesCounts[responseStatus];
        }

        if (200 <= nResponseStatus && nResponseStatus < 300) {
          total200 += metric.statusesCounts[responseStatus];
        }

        if (300 <= nResponseStatus && nResponseStatus < 400) {
          total300 += metric.statusesCounts[responseStatus];
        }

        if (400 <= nResponseStatus && nResponseStatus < 500) {
          total400 += metric.statusesCounts[responseStatus];
        }

        if (500 <= nResponseStatus && nResponseStatus < 600) {
          total500 += metric.statusesCounts[responseStatus];
        }
      });

      metric["100-199"] = total100;
      metric["200-299"] = total200;
      metric["300-399"] = total300;
      metric["400-199"] = total400;
      metric["500-599"] = total500;

      totalTrace100 += total100;
      totalTrace200 += total200;
      totalTrace300 += total300;
      totalTrace400 += total400;
      totalTrace500 += total500;
    });

    const total = this.state.total;

    const currnetPage = this.state.currentLogPage;
    const maxPages = this.state.maxLogPages;
    const search = this.state.search;

    const HttpTracesTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.Period}</p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.Total)} <br />
              <hr />
              {Object.keys(payload[0].payload.statusesCounts).map(
                (responseStatus) =>
                  responseStatus +
                  ": " +
                  payload[0].payload.statusesCounts[responseStatus] +
                  " \n"
              )}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <div className="log_search_container">
          <input
            type="text"
            onChange={this.onSearchChange}
            value={search}
            placeholder="Search"
            className="log_search_input"
          />
          <button
            onClick={this.cleanSearch}
            className="action_button action_clean_search"
          />
          <button
            onClick={this.search}
            className="action_button action_search_logs"
          />
        </div>
        <PeriodToolbar setPeriod={this.setPeriod} />
        <div className="api_logs_statistics_container">
          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={metrics}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<HttpTracesTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="100-199" fill="#b0bec5" stackId="a" />
                <Bar dataKey="200-299" fill="#a5d6a7" stackId="a" />
                <Bar dataKey="300-399" fill="#80cbc4" stackId="a" />
                <Bar dataKey="400-499" fill="#ffcc80" stackId="a" />
                <Bar dataKey="500-599" fill="#ef9a9a" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(total)} <br />
            100-199: {numberHelper.format(totalTrace100)} | 200-299:{" "}
            {numberHelper.format(totalTrace200)} | 300-399:{" "}
            {numberHelper.format(totalTrace300)} | 400-499:{" "}
            {numberHelper.format(totalTrace400)} | 500-599:{" "}
            {numberHelper.format(totalTrace500)}
          </label>
        </div>

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>

        <table className="logs_table logs_table_top">
          <tr>
            <th className="traces_table_small_column">Method</th>
            <th className="traces_table_description">Description</th>
            <th className="traces_table_column">Date</th>
            <th className="traces_table_small_column">Status</th>
            <th className="traces_table_small_column">Duration</th>
          </tr>
        </table>
        {traces.map((trace) => (
          <Link className="row_website_item" to={"http-trace/" + trace.id}>
            <div className="row_logs_item_div">
              <table className="logs_table">
                <tr>
                  <td className="traces_table_small_column">
                    <label className="logs_label_small_column">
                      {trace.method}
                    </label>
                  </td>
                  <td className="traces_table_description">
                    <label className="log_item_message">{trace.path}</label>
                    <label className="log_item_sessions">
                      <b>Pattern match:</b> {trace.patternMatch}
                    </label>
                    {trace.apiUsageId ? (
                      <label className="log_item_sessions">
                        <b>API usage:</b> {trace.apiUsageId}
                      </label>
                    ) : null}
                    {trace.userSession ? (
                      <label className="log_item_sessions">
                        <b>User session:</b> {trace.userSession}
                      </label>
                    ) : null}
                  </td>
                  <td className="traces_table_column">
                    <label className="traces_label_column">
                      {trace.callDateTime}
                    </label>
                  </td>
                  <td className="traces_table_small_column">
                    <label className="traces_label_small_column">
                      {trace.responseStatus}
                    </label>
                  </td>
                  <td className="traces_table_small_column">
                    <label className="traces_label_small_column">
                      {trace.duration} ms
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </Link>
        ))}

        <div className="log_paging_container">
          <button
            className="action_button_log action_log_page_back"
            disabled={currnetPage === 1}
            onClick={this.pageBack}
          />
          <label className="log_page_count">
            {currnetPage}/{maxPages}
          </label>
          <button
            className="action_button_log action_log_page_next"
            disabled={currnetPage === maxPages}
            onClick={this.pageNext}
          />
        </div>
      </div>
    );
  }
}
