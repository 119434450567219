import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import { Link } from "react-router-dom";
import apiService from "../../services/api.service";

export default class ApiHttpTraceDetail extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.state = {
      method: "",
      responseStatus: 0,
      callDateTime: "",
      duration: 0,
      path: "",
      patternMatch: "",
      apiUsageId: "",
      userSession: "",
      loadingItems: false,
    };
  }

  componentDidMount() {
    var pathSplit = window.location.pathname.split("/");
    var httpTraceId = pathSplit[pathSplit.length - 1];
    var apiId = pathSplit[pathSplit.length - 3];

    document.title = "Http trace group detail | Console - davidsimak.dev";

    this.setState(
      {
        apiId: apiId,
        httpTraceId: httpTraceId,
        loadingItems: true,
      },
      () => this.getData()
    );
  }

  getData() {
    apiService
      .getHttpTrace(this.state.httpTraceId)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          method: responseData.method,
          responseStatus: responseData.responseStatus,
          callDateTime: responseData.callDateTime,
          duration: responseData.duration,
          path: responseData.path,
          patternMatch: responseData.patternMatch,
          apiUsageId: responseData.apiUsageId,
          userSession: responseData.userSession,
          loadingItems: false,
        });

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data

    const method = this.state.method;
    const responseStatus = this.state.responseStatus;
    const callDateTime = this.state.callDateTime;
    const duration = this.state.duration;
    const path = this.state.path;
    const patternMatch = this.state.patternMatch;
    const apiUsageId = this.state.apiUsageId;
    const userSession = this.state.userSession;

    const loadingItems = this.state.loadingItems;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to={`/apis/${this.state.apiId}?tabKey=HTTP_TRACE_GROUPS`}>
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="50"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>Http trace group detail</h1>
            </div>
          </header>

          <div className="custom_container mt-3">
            <div className="log_detail_container">
              <label className="log_detail_message">{path}</label>
              <div>
                <label className="log_detail_title">Pattern match</label>
                <label className="log_detail_value">{patternMatch}</label>
              </div>
              <div>
                <label className="log_detail_title">Call date</label>
                <label className="log_detail_value">{callDateTime}</label>
              </div>
              <hr />
              <div className="http_trace_detail_basic_info_container">
                <label className="log_detail_basic_info_title">Method</label>
                <label className="log_detail_basic_info_value">{method}</label>
              </div>
              <div className="http_trace_detail_basic_info_container">
                <label className="log_detail_basic_info_title">
                  Response status
                </label>
                <label className="log_detail_basic_info_value">
                  {responseStatus}
                </label>
              </div>
              <div className="http_trace_detail_basic_info_container">
                <label className="log_detail_basic_info_title">Duration</label>
                <label className="log_detail_basic_info_value">
                  {duration} ms
                </label>
              </div>
              <hr />

              {apiUsageId ? (
                <div>
                  <label className="log_detail_title">API usage</label>
                  <label className="log_detail_value">{apiUsageId}</label>
                </div>
              ) : null}

              {userSession ? (
                <div>
                  <label className="log_detail_title">User session</label>
                  <label className="log_detail_value">{userSession}</label>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
