import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import memorySizeHelper from "../../helpers/memory-size-helper";
import apiService from "../../services/api.service";
import graphHelper from "../../helpers/graph-helper";
import PeriodToolbarReport from "../others/period-toolbar-report.component";
import numberHelper from "../../helpers/number-helper";

export default class ApiDailyReport extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      apiId: null,
      startDateTime: null,
      endDateTime: null,
      totalLogs: 0,
      totalLogsDebug: 0,
      totalLogsInfo: 0,
      totalLogsWarn: 0,
      totalLogsError: 0,
      totalLogsFatal: 0,
      totalTraces: 0,
      totalTraces100_199: 0,
      totalTraces200_299: 0,
      totalTraces300_399: 0,
      totalTraces400_499: 0,
      totalTraces500_599: 0,
      apiStatusUpPercent: 0,
      databaseStatusUpPercent: 0,
      pingStatusUpPercent: 0,
      livenessStatusUpPercent: 0,
      readinessStatusUpPercent: 0,
      avgCpuUsed: 0,
      avgMemoryUsed: 0,
      minCpuUsed: 0,
      minMemoryUsed: 0,
      maxCpuUsed: 0,
      maxMemoryUsed: 0,
      items: [],
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      apiId: this.props.apiId,
    });
  }

  getData() {
    this.props.onLoadingStateChange(true);
    var body = {
      id: this.state.apiId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };

    apiService
      .getDailyReport(body)
      .then((response) => {
        const responseData = response.data;
        this.setState({
          totalLogs: responseData.totalLogs,
          totalLogsDebug: responseData.totalLogsDebug,
          totalLogsInfo: responseData.totalLogsInfo,
          totalLogsWarn: responseData.totalLogsWarn,
          totalLogsError: responseData.totalLogsError,
          totalLogsFatal: responseData.totalLogsFatal,
          totalTraces: responseData.totalTraces,
          totalTraces100_199: responseData.totalTraces100_199,
          totalTraces200_299: responseData.totalTraces200_299,
          totalTraces300_399: responseData.totalTraces300_399,
          totalTraces400_499: responseData.totalTraces400_499,
          totalTraces500_599: responseData.totalTraces500_599,
          apiStatusUpPercent: responseData.apiStatusUpPercent,
          databaseStatusUpPercent: responseData.databaseStatusUpPercent,
          pingStatusUpPercent: responseData.pingStatusUpPercent,
          livenessStatusUpPercent: responseData.livenessStatusUpPercent,
          readinessStatusUpPercent: responseData.readinessStatusUpPercent,
          avgCpuUsed: responseData.avgCpuUsed,
          avgMemoryUsed: responseData.avgMemoryUsed,
          minCpuUsed: responseData.minCpuUsed,
          minMemoryUsed: responseData.minMemoryUsed,
          maxCpuUsed: responseData.maxCpuUsed,
          maxMemoryUsed: responseData.maxMemoryUsed,
          items: responseData.items,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  render() {
    // Data
    const processorUsage = [];
    const memoryUsage = [];
    const apiStatuses = [];
    const databaseStatuses = [];
    const pingStatuses = [];
    const livenessStateStatuses = [];
    const readinessStateStatuses = [];

    const totalLogs = this.state.totalLogs;
    const totalLogsDebug = this.state.totalLogsDebug;
    const totalLogsInfo = this.state.totalLogsInfo;
    const totalLogsWarn = this.state.totalLogsWarn;
    const totalLogsError = this.state.totalLogsError;
    const totalLogsFatal = this.state.totalLogsFatal;
    const totalTraces = this.state.totalTraces;
    const totalTraces100_199 = this.state.totalTraces100_199;
    const totalTraces200_299 = this.state.totalTraces200_299;
    const totalTraces300_399 = this.state.totalTraces300_399;
    const totalTraces400_499 = this.state.totalTraces400_499;
    const totalTraces500_599 = this.state.totalTraces500_599;
    const apiStatusUpPercent = this.state.apiStatusUpPercent;
    const databaseStatusUpPercent = this.state.databaseStatusUpPercent;
    const pingStatusUpPercent = this.state.pingStatusUpPercent;
    const livenessStatusUpPercent = this.state.livenessStatusUpPercent;
    const readinessStatusUpPercent = this.state.readinessStatusUpPercent;
    const avgCpuUsed = this.state.avgCpuUsed;
    const avgMemoryUsed = this.state.avgMemoryUsed;
    const minCpuUsed = this.state.minCpuUsed;
    const minMemoryUsed = this.state.minMemoryUsed;
    const maxCpuUsed = this.state.maxCpuUsed;
    const maxMemoryUsed = this.state.maxMemoryUsed;
    const items = this.state.items;

    items.forEach((usage) => {
      memoryUsage.push({
        period: usage.reportDateTime,
        Avg: usage.avgMemoryUsed,
        Min: usage.minMemoryUsed,
        Max: usage.maxMemoryUsed,
      });

      processorUsage.push({
        period: usage.reportDateTime,
        Avg: usage.avgCpuUsed,
        Min: usage.minCpuUsed,
        Max: usage.maxCpuUsed,
      });

      if (usage.apiStatusUpPercent) {
        apiStatuses.push({
          UP: usage.apiStatusUpPercent,
          DOWN: 100 - usage.apiStatusUpPercent,
          period: usage.reportDateTime,
        });
      } else {
        apiStatuses.push(null);
      }

      if (usage.databaseStatusUpPercent) {
        databaseStatuses.push({
          UP: usage.databaseStatusUpPercent,
          DOWN: 100 - usage.databaseStatusUpPercent,
          period: usage.reportDateTime,
        });
      } else {
        databaseStatuses.push(null);
      }

      if (usage.pingStatusUpPercent) {
        pingStatuses.push({
          UP: usage.pingStatusUpPercent,
          DOWN: 100 - usage.pingStatusUpPercent,
          period: usage.reportDateTime,
        });
      } else {
        pingStatuses.push(null);
      }

      if (usage.livenessStatusUpPercent) {
        livenessStateStatuses.push({
          UP: usage.livenessStatusUpPercent,
          DOWN: 100 - usage.livenessStatusUpPercent,
          period: usage.reportDateTime,
        });
      } else {
        livenessStateStatuses.push(null);
      }

      if (usage.readinessStatusUpPercent) {
        readinessStateStatuses.push({
          UP: usage.readinessStatusUpPercent,
          DOWN: 100 - usage.readinessStatusUpPercent,
          period: usage.reportDateTime,
        });
      } else {
        readinessStateStatuses.push(null);
      }

      // traces
      usage["100-199"] = usage.totalTraces100_199;
      usage["200-299"] = usage.totalTraces200_299;
      usage["300-399"] = usage.totalTraces300_399;
      usage["400-499"] = usage.totalTraces400_499;
      usage["500-599"] = usage.totalTraces500_599;

      // logs
      usage.Debug = usage.totalLogsDebug;
      usage.Info = usage.totalLogsInfo;
      usage.Warn = usage.totalLogsWarn;
      usage.Error = usage.totalLogsError;
      usage.Fatal = usage.totalLogsFatal;
    });

    const ProcessorTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Max: {payload[0].payload.Max}%<br />
              Avg: {payload[0].payload.Avg}%<br />
              Min: {payload[0].payload.Min}%
            </p>
          </div>
        );
      }

      return null;
    };

    const MemoryTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Max: {memorySizeHelper.convertFromBytes(payload[0].payload.Max)}
              <br />
              Avg: {memorySizeHelper.convertFromBytes(payload[0].payload.Avg)}
              <br />
              Min: {memorySizeHelper.convertFromBytes(payload[0].payload.Min)}
            </p>
          </div>
        );
      }

      return null;
    };

    const StatusTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              Up: {payload[0].payload.UP ? payload[0].payload.UP : 0} %
              <br />
              Down: {payload[0].payload.DOWN ? payload[0].payload.DOWN : 0} %
              <br />
              Out of service:{" "}
              {payload[0].payload.OUT_OF_SERVICE
                ? payload[0].payload.OUT_OF_SERVICE
                : 0}{" "}
              %
            </p>
          </div>
        );
      }

      return null;
    };

    const LogsTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.totalLogs)}
              <br />
              <hr />
              Info: {numberHelper.format(payload[0].payload.Info)}
              <br />
              Warn: {numberHelper.format(payload[0].payload.Warn)}
              <br />
              Error: {numberHelper.format(payload[0].payload.Error)}
              <br />
              Fatal: {numberHelper.format(payload[0].payload.Fatal)}
              <br />
              Debug: {numberHelper.format(payload[0].payload.Debug)}
            </p>
          </div>
        );
      }

      return null;
    };

    const HttpTracesTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">
              {payload[0].payload.reportDateTime}
            </p>
            <p className="custom-tooltip-desc">
              Total: {numberHelper.format(payload[0].payload.totalTraces)}{" "}
              <br />
              <hr />
              100-199:{" "}
              {numberHelper.format(payload[0].payload.totalTraces100_199)}
              <br />
              200-299:{" "}
              {numberHelper.format(payload[0].payload.totalTraces200_299)}
              <br />
              300-399:{" "}
              {numberHelper.format(payload[0].payload.totalTraces300_399)}
              <br />
              400-499:{" "}
              {numberHelper.format(payload[0].payload.totalTraces400_499)}
              <br />
              500-599:{" "}
              {numberHelper.format(payload[0].payload.totalTraces500_599)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbarReport setPeriod={this.setPeriod} />

        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="api_historic_usage_title">Logs statistics</label>

          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<LogsTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="Debug" fill="#b0bec5" stackId="a" />
                <Bar dataKey="Info" fill="#a5d6a7" stackId="a" />
                <Bar dataKey="Warn" fill="#ffcc80" stackId="a" />
                <Bar dataKey="Error" fill="#ef9a9a" stackId="a" />
                <Bar dataKey="Fatal" fill="#f48fb1" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(totalLogs)}
            <br />
            Info: {numberHelper.format(totalLogsInfo)} | Warn:{" "}
            {numberHelper.format(totalLogsWarn)} | Error:{" "}
            {numberHelper.format(totalLogsError)} | Fatal:{" "}
            {numberHelper.format(totalLogsFatal)} | Debug:{" "}
            {numberHelper.format(totalLogsDebug)}
          </label>
        </div>
        <div
          className="api_logs_statistics_container"
          style={{ margin: "25px" }}
        >
          <label className="api_historic_usage_title">
            Http traces statistics
          </label>

          <div className="api_logs_statistics_graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={items}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<HttpTracesTooltip />} />
                <YAxis tickFormatter={numberHelper.format} />
                <Legend />
                <Bar dataKey="100-199" fill="#b0bec5" stackId="a" />
                <Bar dataKey="200-299" fill="#a5d6a7" stackId="a" />
                <Bar dataKey="300-399" fill="#80cbc4" stackId="a" />
                <Bar dataKey="400-499" fill="#ffcc80" stackId="a" />
                <Bar dataKey="500-599" fill="#ef9a9a" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <label className="api_logs_statistics_summary">
            Total: {numberHelper.format(totalTraces)} <br />
            100-199: {numberHelper.format(totalTraces100_199)} | 200-299:{" "}
            {numberHelper.format(totalTraces200_299)} | 300-399:{" "}
            {numberHelper.format(totalTraces300_399)} | 400-499:{" "}
            {numberHelper.format(totalTraces400_499)} | 500-599:{" "}
            {numberHelper.format(totalTraces500_599)}
          </label>
        </div>
        {processorUsage.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Processor</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={processorUsage}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<ProcessorTooltip />} />
                  <YAxis tickFormatter={graphHelper.formatPercent} />
                  <Legend />
                  <Line dataKey="Max" stroke="#ef9a9a" />
                  <Line dataKey="Avg" stroke="#ffcc80" />
                  <Line dataKey="Min" stroke="#a5d6a7" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {memoryUsage.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Memory</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={memoryUsage}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<MemoryTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.convertFromBytesTickFormat}
                  />
                  <Legend />
                  <Line dataKey="Max" stroke="#ef9a9a" />
                  <Line dataKey="Avg" stroke="#ffcc80" />
                  <Line dataKey="Min" stroke="#a5d6a7" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {apiStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">API status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={apiStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {databaseStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Database status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={databaseStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {pingStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Ping status</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={pingStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {livenessStateStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Liveness state</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={livenessStateStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
        {readinessStateStatuses.length ? (
          <div className="api_historic_usage_div">
            <label className="api_historic_usage_title">Readiness state</label>
            <div className="api_historic_usage_graph">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={readinessStateStatuses}>
                  <ReferenceLine y={0} stroke="#212121" />
                  <Tooltip content={<StatusTooltip />} />
                  <YAxis
                    tickFormatter={graphHelper.formatPercent}
                    type="number"
                    domain={[0, 100]}
                  />
                  <Bar dataKey="DOWN" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="OUT_OF_SERVICE" fill="#a5d6a7" stackId="a" />
                  <Bar dataKey="UP" fill="#a5d6a7" stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
