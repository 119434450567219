import http from "../helpers/http-common-reporting";

class InfrastrucutreService {
  getAll() {
    return http.get(`/infrastructure/`);
  }

  getInfo(id) {
    return http.get(`/infrastructure/server-info/${id}`);
  }

  getCurrentUsage(id) {
    return http.get(`/infrastructure/current-usage/${id}`);
  }

  getHistoricMetrics(data) {
    return http.post("/infrastructure/history-metrics", data);
  }

  getDailyReport(data) {
    return http.post("/infrastructure/daily-report", data);
  }
}

export default new InfrastrucutreService();
