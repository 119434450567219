import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import PeriodToolbar from "../others/period-toolbar.component";
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import infrastructureService from "../../services/infrastructure.service";
import memorySizeHelper from "../../helpers/memory-size-helper";
import graphHelper from "../../helpers/graph-helper";

export default class ServerHistoricUsage extends Component {
  constructor(props) {
    super(props);
    this.getUsage = this.getUsage.bind(this);
    this.getData = this.getData.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.setPeriod = this.setPeriod.bind(this);

    this.state = {
      serverId: null,
      startDateTime: null,
      endDateTime: null,
      syncIntervalId: null,
      usage: [],
    };
  }

  setPeriod(startDateTime, endDateTime) {
    this.setState(
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
      () => this.getData()
    );
  }

  componentDidMount() {
    this.setState({
      serverId: this.props.serverId,
    });
    // this.initIntervals();
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getData() {
    this.props.onLoadingStateChange(true);
    this.getUsage();
  }

  getUsage() {
    var body = {
      id: this.state.serverId,
      startDate: this.state.startDateTime,
      endDate: this.state.endDateTime,
    };

    console.log(body);
    infrastructureService
      .getHistoricMetrics(body)
      .then((response) => {
        this.setState({
          usage: response.data,
        });

        this.props.onLoadingStateChange(false);

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getUsage();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data
    const usage = this.state.usage;
    const processorUsage = [];
    const memoryUsage = [];
    const storageUsage = [];
    const totalMemory = usage.length > 0 ? usage[0].totalMemory : 0;
    const totalStorage = usage.length > 0 ? usage[0].totalStorage : 0;

    this.state.usage.forEach((usage) => {
      memoryUsage.push({
        period: usage.period,
        Avg: usage.avgUsedMemory,
        Min: usage.minUsedMemory,
        Max: usage.maxUsedMemory,
      });

      storageUsage.push({
        period: usage.period,
        Avg: usage.avgUsedStorage,
        Min: usage.minUsedStorage,
        Max: usage.maxUsedStorage,
      });

      processorUsage.push({
        period: usage.period,
        Avg: usage.avgCpuAllActivePercent,
        Min: usage.minCpuAllActivePercent,
        Max: usage.maxCpuAllActivePercent,
      });
    });

    const ProcessorTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b> {payload[0].payload.Max}%<br />
              <b>Avg:</b> {payload[0].payload.Avg}%<br />
              <b>Min:</b> {payload[0].payload.Min}%
            </p>
          </div>
        );
      }

      return null;
    };

    const MemoryTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Max)}
              <br />
              <b>Avg:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Avg)}
              <br />
              <b>Min:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Min)}
              <br />
              <b>Total:</b> {memorySizeHelper.convertFromMegaBytes(totalMemory)}
            </p>
          </div>
        );
      }

      return null;
    };

    const StorageTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-title">{payload[0].payload.period}</p>
            <p className="custom-tooltip-desc">
              <b>Max:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Max)}
              <br />
              <b>Avg:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Avg)}
              <br />
              <b>Min:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(payload[0].payload.Min)}
              <br />
              <b>Total:</b>{" "}
              {memorySizeHelper.convertFromMegaBytes(totalStorage)}
            </p>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <PeriodToolbar setPeriod={this.setPeriod} />
        <div className="server_historic_usage_div">
          <label className="server_historic_usage_title">Processor</label>
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={processorUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<ProcessorTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  tickFormatter={graphHelper.formatPercent}
                />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="server_historic_usage_div">
          <label className="server_historic_usage_title">Memory</label>
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={memoryUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<MemoryTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, totalMemory]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <YAxis />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="server_historic_usage_div">
          <label className="server_historic_usage_title">Storage</label>
          <div className="server_historic_usage_graph">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={storageUsage}>
                <ReferenceLine y={0} stroke="#212121" />
                <Tooltip content={<StorageTooltip />} />
                <YAxis
                  type="number"
                  domain={[0, totalStorage]}
                  tickFormatter={graphHelper.convertFromMegaBytesTickFormat}
                />
                <Legend />
                <Line type="monotone" dataKey="Max" stroke="#ef9a9a" />
                <Line type="monotone" dataKey="Avg" stroke="#ffcc80" />
                <Line type="monotone" dataKey="Min" stroke="#a5d6a7" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
