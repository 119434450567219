import React, { Component } from "react";
import { Link } from "react-router-dom";
import CookieHelper from "../../helpers/cookie-helper";
import apiService from "../../services/api.service";

export default class ApiList extends Component {
  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.getData = this.getData.bind(this);
    this.singOut = this.singOut.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setSearchVisibility = this.setSearchVisibility.bind(this);
    this.searching = this.searching.bind(this);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.setItems = this.setItems.bind(this);

    this.state = {
      apis: [],
      apisBackup: [],
      loadingItems: true,
      isSearchVisible: false,
      syncIntervalId: "",
      searchWords: "",
    };
  }

  setSearchVisibility = () => {
    if (this.state.isSearchVisible) {
      this.setState(
        {
          isSearchVisible: false,
          canSync: true,
          searchWords: "",
        },
        this.setItems
      );
    } else {
      this.setState(
        {
          isSearchVisible: true,
          canSync: false,
        },
        () => {
          document.getElementById("input_search").focus();
        }
      );
    }
  };

  setItems() {
    const searchWords = this.state.searchWords;
    const apis = this.state.apisBackup;

    this.state.apis = [];

    const finalapis = [];

    if (searchWords.length > 0) {
      apis.forEach((item) => {
        if (item.apiName.toLowerCase().includes(searchWords)) {
          finalapis.push(item);
        }
      });
    } else {
      finalapis.push(...apis);
    }

    this.setState({
      apis: finalapis,
    });
  }

  searching(e) {
    const searchWords = e.target.value.toLowerCase();
    this.setState(
      {
        searchWords: searchWords,
      },
      () => this.setItems()
    );
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=console";
    }
  };

  refresh = () => {
    clearInterval(this.state.syncIntervalId);
    this.getData();
    this.initIntervals();
  };

  componentDidMount() {
    this.getData();
    this.initIntervals();
    document.title = "APIs | Console - davidsimak.dev";
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getData() {
    this.setState({
      loadingItems: true,
    });

    this.getItems();
  }

  getItems() {
    apiService
      .getAll()
      .then((response) => {
        this.setState({
          apis: [],
          apisBackup: [],
        });

        const apis = response.data;

        this.setState(
          {
            apisBackup: apis,
            loadingItems: false,
          },
          () => this.setItems()
        );

        CookieHelper.extendValidity();
      })
      .catch((e) => {});
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  backgroundSync() {
    this.getItems();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data
    const apis = this.state.apis;

    // UI changes
    const loadingItems = this.state.loadingItems;
    const isSearchVisible = this.state.isSearchVisible;

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <div>
          <header className="">
            <div className="title">
              <Link to="/">
                <img
                  title="Back"
                  src={require("../../icons/left.svg").default}
                  height="50"
                  width="40"
                  alt="Back"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
              <h1>APIs</h1>
            </div>

            <nav>
              <ul>
                <li>
                  <div className="dropdown">
                    <button
                      title="Account Info"
                      className="action_button action_account_info"
                    />
                    <div className="dropdown_content dropdown_content_account_info">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.davidsimak.dev/account/"
                      >
                        Account Info
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={this.singOut}>Sign out</a>
                    </div>
                  </div>
                </li>
                <li className="menu_divider">|</li>
                <li>
                  <button
                    title="Refresh"
                    className="action_button action_refresh"
                    onClick={this.refresh}
                  />
                </li>
                <li>
                  <button
                    title="Search"
                    className="action_button action_search"
                    onClick={this.setSearchVisibility}
                  />
                </li>
              </ul>
            </nav>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                {isSearchVisible ? (
                  <div className="div_search">
                    <input
                      id="input_search"
                      placeholder="Type to search..."
                      className="input_search"
                      type="text"
                      onChange={this.searching}
                    />
                    <button
                      className="action_button action_close_search"
                      title="Close searchbar"
                      onClick={this.setSearchVisibility}
                    />
                  </div>
                ) : null}

                <table className="api_table table_top">
                  <tr>
                    <th className="api_table_td_name">API name</th>
                    <th>
                      <label className="row_api_item_title">API</label>
                    </th>
                    <th>
                      <label className="row_api_item_title">DB</label>
                    </th>
                    <th>
                      <label className="row_api_item_title">Ping</label>
                    </th>
                    <th>
                      <label className="row_api_item_title">Liveness</label>
                    </th>
                    <th>
                      <label className="row_api_item_title">Readiness</label>
                    </th>
                  </tr>
                </table>

                {apis.map((api) => (
                  <Link className="row_website_item" to={api.id}>
                    <div className="row_website_item_div">
                      <table className="api_table">
                        <tr>
                          <td className="api_table_td_name">
                            <label className="row_api_item_name">
                              {api.name}
                            </label>
                          </td>
                          <td>
                            <div
                              className={
                                api.apiStatus === "UP"
                                  ? "info_status_true"
                                  : "info_status_false"
                              }
                            />
                          </td>
                          <td>
                            {api.databaseStatus === undefined ? null : (
                              <div
                                className={
                                  api.databaseStatus === "UP"
                                    ? "info_status_true"
                                    : "info_status_false"
                                }
                              />
                            )}
                          </td>
                          <td>
                            <div
                              className={
                                api.pingStatus === "UP"
                                  ? "info_status_true"
                                  : "info_status_false"
                              }
                            />
                          </td>
                          <td>
                            <div
                              className={
                                api.livenessStateStatus === "UP"
                                  ? "info_status_true"
                                  : "info_status_false"
                              }
                            />
                          </td>
                          <td>
                            <div
                              className={
                                api.readinessStateStatus === "UP"
                                  ? "info_status_true"
                                  : "info_status_false"
                              }
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
