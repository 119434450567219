import React, { Component } from "react";
import CookieHelper from "../../helpers/cookie-helper";
import reactWebsitesService from "../../services/react-websites.service";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

export default class ReactWebsiteInfo extends Component {
  constructor(props) {
    super(props);
    this.getInfo = this.getInfo.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);

    this.state = {
      id: null,
      url: null,
      purpose: null,
      syncIntervalId: null,
      availabilityPercentLast1hour: 0,
      availabilityPercentLast24hour: 0,
      availabilityPercentLast7days: 0,
      unavailabilityPercentLast1hour: 0,
      unavailabilityPercentLast24hour: 0,
      unavailabilityPercentLast7days: 0,
    };
  }

  componentDidMount() {
    this.props.onLoadingStateChange(true);

    this.setState(
      {
        id: this.props.websiteId,
      },
      () => {
        this.getInfo();
      }
    );

    this.initIntervals();
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getInfo() {
    reactWebsitesService
      .getInfo(this.state.id)
      .then((response) => {
        const responseData = response.data;

        this.setState({
          url: responseData.url,
          purpose: responseData.purpose,
          availabilityPercentLast1hour:
            responseData.availabilityPercentLast1hour,
          availabilityPercentLast24hour:
            responseData.availabilityPercentLast24hour,
          availabilityPercentLast7days:
            responseData.availabilityPercentLast7days,
          availabilityPercentLast30days:
            responseData.availabilityPercentLast30days,
          availabilityStatisticsInPeriod:
            responseData.availabilityStatisticsInPeriod,
          availabilityPercentInPeriod: responseData.availabilityPercentInPeriod,
          unavailabilityPercentLast1hour:
            responseData.unavailabilityPercentLast1hour,
          unavailabilityPercentLast24hour:
            responseData.unavailabilityPercentLast24hour,
          unavailabilityPercentLast7days:
            responseData.unavailabilityPercentLast7days,
          unavailabilityPercentLast30days:
            responseData.unavailabilityPercentLast30days,
          unavailabilityPercentInPeriod:
            responseData.unavailabilityPercentInPeriod,
        });

        CookieHelper.extendValidity();

        document.title =
          responseData.name +
          " (" +
          responseData.url +
          ") | React websites | Console - davidsimak.dev";

        this.props.setName(responseData.name);
        this.props.onLoadingStateChange(false);
      })
      .catch((e) => {});
  }

  backgroundSync() {
    this.getInfo();
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    this.setState({
      syncIntervalId: syncIntervalId,
    });
  }

  render() {
    // Data
    const url = this.state.url;
    const purpose = this.state.purpose;

    const availabilityPercentLast1hour =
      this.state.availabilityPercentLast1hour;
    const availabilityPercentLast24hour =
      this.state.availabilityPercentLast24hour;
    const availabilityPercentLast7days =
      this.state.availabilityPercentLast7days;

    const availabilityPercentLast1hourData = [
      {
        value: availabilityPercentLast1hour,
      },
      {
        value: this.state.unavailabilityPercentLast1hour,
      },
    ];
    const availabilityPercentLast24hourData = [
      {
        value: availabilityPercentLast24hour,
      },
      {
        value: this.state.unavailabilityPercentLast24hour,
      },
    ];
    const availabilityPercentLast7daysData = [
      {
        value: availabilityPercentLast7days,
      },
      {
        value: this.state.unavailabilityPercentLast7days,
      },
    ];

    const colors = ["#a5d6a7", "#ef9a9a"];

    return (
      <div>
        <div>
          <div className="website_info_div">
            <label className="website_info_title">Purpose</label>

            <label className="website_info_value">{purpose}</label>

            <label className="website_info_title">Website url</label>

            <label className="website_info_value">
              <a target="_blank" rel="noopener noreferrer" href={url}>
                {url}
              </a>
            </label>

            <br />
            <br />
            <hr />
            <label className="website_availability_info_div_label">
              Availability
            </label>
            <div className="website_availability_div">
              <div className="website_availability_info_div">
                <div className="website_availability_last_graph">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={availabilityPercentLast1hourData}
                        dataKey="value"
                        cx="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="#82ca9d"
                        startAngle={0}
                        endAngle={180}
                      >
                        {availabilityPercentLast1hourData.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <label className="website_availability_count">
                  {availabilityPercentLast1hour}%
                </label>
                <label className="website_availability_label">
                  Last 1 hour
                </label>
              </div>
              <div className="website_availability_info_div">
                <div className="website_availability_last_graph">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={availabilityPercentLast24hourData}
                        dataKey="value"
                        cx="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="#82ca9d"
                        startAngle={0}
                        endAngle={180}
                      >
                        {availabilityPercentLast24hourData.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <label className="website_availability_count">
                  {availabilityPercentLast24hour}%
                </label>
                <label className="website_availability_label">
                  Last 24 hours
                </label>
              </div>
              <div className="website_availability_info_div">
                <div className="website_availability_last_graph">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={availabilityPercentLast7daysData}
                        dataKey="value"
                        cx="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="#82ca9d"
                        startAngle={0}
                        endAngle={180}
                      >
                        {availabilityPercentLast7daysData.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <label className="website_availability_count">
                  {availabilityPercentLast7days}%
                </label>
                <label className="website_availability_label">
                  Last 7 days
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
